import { Card, CardActionArea, CardContent, CardMedia, Typography } from '@mui/material';
import { Link } from 'react-router-dom';
import React from 'react';

const BlogCard = (props) => {
  const {
    id,
    authorName,
    categories,
    cover,
    publishedAt,
    link,
    size,
    title,
    content,
    ...other
  } = props;

  const urlTitle =  link && link.toLowerCase()
                                  .trim()
                                  .replace(/[^\w\s-]/g, '')
                                  .replace(/[\s_-]+/g, '-')
                                  .replace(/^-+|-+$/g, '');

  return (
    <Card
      sx={{
        boxShadow: "0 2px 30px rgb(0 0 0 / 0.1)",
        height: '100%', 
        display: 'flex', 
        flexDirection: 'column'
      }}
      {...other}
    >
      <Link to={`/blog/${id}/${urlTitle}`} style={{
        textDecoration: "none"
      }}>
        <CardMedia
          sx={{ pt: size === "large" ? '56.25%' : '100%' }} // 16:9
          image={cover ? cover : '/blog/blog-thumbnail-default.png'}
          title={title} />
        <CardActionArea>
          <CardContent 
            sx={{ flexGrow: 1 }}>
            <Typography variant="h5"
              color="text.primary"
              style={{
                display: "-webkit-box",
                WebkitBoxOrient: "vertical",
                WebkitLineClamp: 2,
                overflow: "hidden",
                lineHeight: 1.1,
                height: "50px",
                color: "text.main",
              }}
            >
              {title}
            </Typography>
          </CardContent>
        </CardActionArea>
      </Link>
    </Card>
  );
};

export default BlogCard;
