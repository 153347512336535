import React from "react";
import {
  Box,
  Container,
  useTheme,
  useMediaQuery,
} from "@mui/material";
import SEO from "../../components/SEO/SEO";
import { CallToActionOrganizers, Testimonials } from "../../container";

const Demo = () => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("md")); // 'md' corresponds to 1000px in MUI's default breakpoints

  const styles = {
    image: {
      maxWidth: "545px",
      borderRadius: "30px",
    },
    container: {
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      overflow: "hidden",
      padding: isMobile ? "30px 30px 0 30px" : "60px 30px 0 30px",
    },
    terms: {
      padding: "20px 30px",
      borderRadius: "8px",
      flexDirection: "column",
      margin: 0,
      width: "100%",
      textAlign: "left",
      background: "#f1f1f1",
      div: {
        position: "relative",
        padding: "20px",
        borderRadius: "5px",
        background: "#ededed",
        boxShadow: "1px 1px 3px #d4d4d4",
        "@media (max-width: 810px)": {
          marginBottom: "30px",
        },
      },
      title: {
        paddingTop: "1em",
        textAlign: "center",
        margin: "0px",
        color: "rgb(18, 15, 67)",
        fontSize: "38px",
        fontWeight: "700",
        marginBottom: ".5em",
      },
      p: {
        margin: 0,
        width: "100%",
        textAlign: "justify",
        "@media (max-width: 810px)": {
          fontSize: "15px",
        },
      },
      ul: {
        listStyleType: "disc",
        ul: {
          listStyleType: "circle",
        },
      },
    },
  };

  return (
    <Box id="terms" >
      <Box style={{ width: "100%", height: "100px", background: "#050327" }} />
      <SEO
        title="Fanz | Demo"
        description="Programa de Demo de Fanz"
        image="%PUBLIC_URL%/og_image.jpg"
      />
      <Container sx={styles.container}>
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: isMobile ? "center" : "space-between",
              gap: isMobile ? 0 : "30px",
              flexDirection: "column",
            }}
          >
            <div>
              <h1
                variant="h1"
                style={{
                  fontWeight: "700",
                  letterSpacing: "3px",
                  textAlign: "center",
                  fontSize: "1.6em",
                  color: "#603CFF",
                  width: "100%",
                  marginBottom: "10px",
                }}
              >
                <span style={{ position: "relative", fontFamily: `"Blatant", sans-serif`, fontWeight: "bold !important" }}>
                  Nuestra plataforma todo-en-uno para gestionar eventos
                </span>
              </h1>
              <h1 style={{ textAlign: "center" }}>
                <span style={{ position: "relative", fontFamily: `"Blatant", sans-serif`, fontWeight: "bold !important" }}>
                  Construí tu pagina para vender tickets en tan solo
                  
                  <span style={{ position: "relative", fontFamily: `"Blatant", sans-serif`, fontWeight: "bold !important", marginLeft: "8px" }}>
                  5 minutos
                  <svg
                    viewBox="0 0 272 8"
                    focusable="false"
                    class="chakra-icon chakra-icon css-fsr128"
                    style={{
                      width: "100%",
                      position: "absolute",
                      bottom: "-12px",
                      left: "0",
                    }}
                  >
                    <svg
                      width="272"
                      height="8"
                      viewBox="0 0 272 8"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        fill-rule="evenodd"
                        clip-rule="evenodd"
                        d="M269.324 7.99445C165.26 1.51861 48.1761 5.29673 2.66743 7.99495C1.28982 8.07663 0.0981205 7.15583 0.00570267 5.93829C-0.0867152 4.72075 0.955142 3.66752 2.33276 3.58584C47.9765 0.879607 165.306 -2.90852 269.675 3.58635C271.053 3.67205 272.091 4.72832 271.994 5.94559C271.897 7.16285 270.702 8.08016 269.324 7.99445Z"
                        fill="url(#paint0_linear_999_11627)"
                      ></path>
                      <defs>
                        <linearGradient
                          id="paint0_linear_999_11627"
                          x1="24.9606"
                          y1="6.97522"
                          x2="209.694"
                          y2="-91.8367"
                          gradientUnits="userSpaceOnUse"
                        >
                          <stop stop-color="#3D1DFF"></stop>
                          <stop offset="0.223953" stop-color="#6147FF"></stop>
                          <stop offset="0.46354" stop-color="#D451FF"></stop>
                          <stop offset="0.750004" stop-color="#EC458D"></stop>
                          <stop offset="1" stop-color="#FFCA8B"></stop>
                        </linearGradient>
                      </defs>
                    </svg>
                  </svg>
                </span>
                </span>
              </h1>
            </div>
            <div
              style={{
                width: "100%",
                display: "flex",
                alignItems: "center",
                justifyContent: isMobile ? "center" : "flex-end",
              }}
            >
              <Box sx={{ position: 'relative', paddingBottom: '56.25%', height: 0, overflow: 'hidden', width: '100%', background: '#000', margin: '1.5em 0' }}>
                <iframe
                  width="560"
                  height="315"
                  src="https://www.youtube.com/embed/rjrcVaGZ_NE"
                  title="YouTube video player"
                  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; fullscreen"
                  allowFullScreen
                  style={{ position: 'absolute', top: 0, left: 0, width: '100%', height: '100%' }}
                ></iframe>
              </Box>
            </div>
          </div>
      </Container>
      <CallToActionOrganizers
        calendar="https://calendar.google.com/calendar/appointments/schedules/AcZssZ3tiVaJXC72sqNAVndWHBatZcg09ZmvmW4VVwPrOFgxUC-wlORgdqGxcSrK2ws2b2Lz3If4wa5I?gv=true"
        title="Hablemos de tus eventos"
        subtitle="Agendá una llamada con Julian (CEO de Fanz)"
      />
      <Testimonials inDemo/>
    </Box>
  );
};

export default Demo;

