import { Button, Box, useMediaQuery } from "@mui/material";
import { useTheme } from "@mui/material/styles";
import FanzLogo from "../../assets/fanz-logo.png";
import FanzWhiteLogo from "../../assets/fanz-white-logo.png";
import { useState, useEffect } from "react";
import { Link } from "react-router-dom";
// import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
// import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import MobileDrawer from "../MobileDrawer/MobileDrawer";

const Navbar = () => {
  const [isScrolled, setIsScrolled] = useState(false);
  // const [anchorEl, setAnchorEl] = useState(null);
  // const [currentMenu, setCurrentMenu] = useState(null);

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("md"));

  useEffect(() => {
    const handleScroll = () => {
      // handleMenuClose();
      if (window.scrollY > 100) {
        setIsScrolled(true);
      } else {
        setIsScrolled(false);
      }
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  const headerStyle = {
    position: "fixed",
    top: "0",
    zIndex: "1",
    padding: isScrolled ? "8px 0" : "22px 0px 8px 0px",
    background: isScrolled ? "white" : "transparent",
    width: "100%",
    borderBottom: isScrolled ? "1px solid #eaeaea" : "1px solid transparent",
    transition: "all .5s ease",
  };

  const containerStyle = {
    display: "flex",
    alignItems: "center",
    maxWidth: "1140px",
    width: "90%",
    margin: "0 auto",
    justifyContent: "space-between",
  };

  const logoBoxStyle = {
    maxWidth: { xs: 80, md: 100 },
  };

  const buttonStyle = {
    fontSize: "16px",
    whiteSpace: "nowrap",
    height: "48px",
    borderRadius: "45px",
    cursor: "pointer",
    padding: "0.5em 1em",
    border: isScrolled ? "1px solid #4a25e1" : "1px solid #464567",
    maxWidth: "250px",
    width: "100%",
    color: isScrolled ? "#4a25e1" : "#fff",
    background: isScrolled ? "#fff" : "rgba(255, 255, 255, 0.16)",
  };

  const navStyle = {
    display: "flex",
    alignItems: "center",
    height: "50px",
    marginLeft: "16px",
  };

  const linkStyle = (isScrolled) => ({
    textDecoration: "none",
    fontSize: "16px",
    height: "100%",
    display: "flex",
    alignItems: "center",
    color: isScrolled ? "#040404" : "#FFF",
    fontWeight: "400",
    marginLeft: "24px",
    cursor: "pointer",
    lineHeight: "1.2",
    ml: "48px",
    transition: "500ms",
  });

  // const handleMenuOpen = (event, menu) => {
  //   setAnchorEl(event.currentTarget);
  //   setCurrentMenu(menu);
  // };

  // const handleMenuClose = () => {
  //   setAnchorEl(null);
  //   setCurrentMenu(null);
  // };

  // const StyledMenu = styled(Menu)({
  //   cursor: "pointer",
  //   "& .MuiPopover-paper": {
  //     top: "75px !important",
  //     boxShadow: `1px 1px 10px rgba(0, 0, 0, .1)`,
  //   },
  // });

  return (
    <header style={headerStyle}>
      <Box sx={containerStyle}>
        <div style={{ display: "flex", alignItems: "center" }}>
          <Link
            to="/"
            style={{
              textDecoration: "none",
              display: "flex",
              alignItems: "center",
            }}
          >
            <Box
              sx={logoBoxStyle}
              component="img"
              alt="logo of fanz"
              src={isScrolled ? FanzLogo : FanzWhiteLogo}
            />
            {/* <Box component="span" sx={proBoxStyle}>
              PRO
            </Box> */}
          </Link>
          {!isMobile && (
            <Box sx={navStyle}>
              <Link to="/" style={linkStyle(isScrolled)}>
                Inicio
              </Link>
              <a href="/#caracteristicas" style={linkStyle(isScrolled)}>
                Funcionalidades
              </a>
              <Link to="/demo" style={linkStyle(isScrolled)}>
                Demo
              </Link>
              <Link to="/blog" style={linkStyle(isScrolled)}>
                Blog
              </Link>
              {/* <div
                onMouseEnter={(e) => handleMenuOpen(e, "resources")}
                style={linkStyle(isScrolled)}
              >
                Recursos
                {currentMenu === "resources" ? (
                  <KeyboardArrowUpIcon fontSize="small" />
                ) : (
                  <KeyboardArrowDownIcon fontSize="small" />
                )}
              </div>
              <StyledMenu
                anchorEl={anchorEl}
                open={currentMenu === "resources"}
                onClose={handleMenuClose}
                onMouseLeave={() => {
                  handleMenuClose();
                }}
                autoFocus={false}
                anchorOrigin={{ vertical: "bottom", horizontal: "left" }}
                transformOrigin={{ vertical: "top", horizontal: "left" }}
                disableScrollLock={true}
              >
                <MenuItem onClick={handleMenuClose}>
                  <a
                    style={{ color: "#050327" }}
                    href="/afiliados"
                    onClick={handleMenuClose}
                  >
                    Afiliados
                  </a>
                </MenuItem> */}
                {/* <MenuItem onClick={handleMenuClose}>
                  <a
                    style={{ color: "#050327" }}
                    href="https://help.fanz.events"
                    target="_blank"
                    rel="noreferrer"
                    onClick={handleMenuClose}
                  >
                    Centro de Ayuda
                  </a>
                </MenuItem> */}
                {/* <MenuItem onClick={handleMenuClose}>
                  <a
                    style={{ color: "#050327" }}
                    href="https://help.fanz.events/preguntas-frecuentes"
                    target="_blank"
                    rel="noreferrer"
                    onClick={handleMenuClose}
                  >
                    Preguntas Frecuentes
                  </a>
                </MenuItem> */}
                {/* <MenuItem onClick={handleMenuClose}>
                  <a
                    style={{ color: "#050327" }}
                    href="https://help.fanz.events"
                    target="_blank"
                    rel="noreferrer"
                    onClick={handleMenuClose}
                  >
                    Contáctanos
                  </a>
                </MenuItem> */}
              {/* </StyledMenu> */}
            </Box>
          )}
        </div>
        {!isMobile && (
          <div style={{ display: "flex", alignItems: "center" }}>
            <a
              href="https://panel.fanz.com.ar"
              target="_blank"
              rel="noreferrer"
              style={{ ...linkStyle(isScrolled), marginRight: "12px" }}
            >
              Iniciar sesión
            </a>
            <a href="/#solicitaunademo" style={{ textDecoration: "none" }}>
              <Button variant="primary.main" sx={buttonStyle}>
                Solicitá una demo{" "}
                <svg
                  stroke="currentColor"
                  fill="currentColor"
                  strokeWidth="0"
                  viewBox="0 0 24 24"
                  focusable="false"
                  className="chakra-icon css-13h60r2"
                  height="1em"
                  width="1em"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path fill="none" d="M0 0h24v24H0z"></path>
                  <path d="M10 6L8.59 7.41 13.17 12l-4.58 4.59L10 18l6-6z"></path>
                </svg>{" "}
              </Button>
            </a>
          </div>
        )}
        {isMobile && <MobileDrawer isScrolled={isScrolled} />}
      </Box>
    </header>
  );
};

export default Navbar;
