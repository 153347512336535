import React from "react";
import { Box, Container, IconButton } from "@mui/material";
import ProfileCard from "../../components/ProfileCard/ProfileCard";
import BlockTitle from "../../components/BlockTitle/BlockTitle";
import julianAvatar from "../../assets/profiles/shorsh.jpeg";
import juanAvatar from "../../assets/profiles/colo.jpeg";
import julianAvatar2 from "../../assets/profiles/decentraland-avatar-julian.webp";
import calebAvatar from "../../assets/profiles/decentraland-avatar-caleb.webp";
import nickyAvatar from "../../assets/profiles/decentraland-avatar-nicky.webp";
import matiAvatar from "../../assets/profiles/decentraland-avatar-matias.webp";
import juampiAvatar from "../../assets/profiles/decentraland-avatar-juampi.webp";
import juanAvatar2 from "../../assets/profiles/decentraland-avatar-juan.webp";
import pachaAvatar from "../../assets/profiles/decentraland-avatar-pacha.webp";
import founders from "../../assets/founders.png";
import { FaLinkedin } from "react-icons/fa";
import {Helmet} from "react-helmet";

const teamData = [
  {
  name: "JULIÁN",
  role: "CO-FOUNDER & CEO",
  path: {julianAvatar2},
  },
  {
  name: "JUAN",
  role: "CO-FOUNDER & CTO",
  path: {juanAvatar2},
  },
  {
    name: "CALEB",
    role: "SR FRONT-END DEV",
    path: { calebAvatar },
  },
  {
    name: "NEKS",
    role: "SSR FRONT-END DEV",
    path: { nickyAvatar },
  },
  {
    name: "MATI",
    role: "BLOCKCHAIN DEV",
    path: { matiAvatar },
  },
  {
    name: "JUAMPI",
    role: "CONTENT CREATOR",
    path: { juampiAvatar },
  },
  {
    name: "SOL",
    role: "MARKETING",
    path: { pachaAvatar },
  },
];

const Team = () => {
  return (
    <Box id="team" sx={styles.box}>
      <Helmet>
        <title>{`Fanz | About Us`}</title>
      </Helmet>
      <Container sx={styles.container}>
        <BlockTitle
          tagline="Our Team"
          heading="Meet our founders and team behind the project"
        />
        <img src={founders} alt="founders" sx={styles.foundersImg} />
        <Box sx={styles.founders}>
          <div>
            <img
              src={juanAvatar}
              alt="Juan Oliva"
              style={styles.foundersAvatar}
            />
            <a
              target="_blank"
              href="https://www.linkedin.com/in/juan-martin-oliva/" rel="noreferrer"
            >
              <h2>
                Juan Oliva{" "}
                <IconButton aria-label="list icon" sx={styles.social}>
                  <FaLinkedin />
                </IconButton>
              </h2>
              <h4>
                CO-FOUNDER & CTO
              </h4>
            </a>
            <p>
              Software Engineering student about to graduate from ITBA.
              Background in Software Development and Cybersecurity. Worked as a
              Web Developer for several projects utilizing various technologies
              such as NodeJS, ASP.NET, Java, React, and Solidity. Part-time Bug
              Bounty hunter for Hackerone since 2019 looking for and reporting
              web vulnerabilities. Been responsible for vulnerability reports
              for organizations like Paypal, Apple, and the US Department of
              Defense. Mainly interested in Ethereum and Blockchain development
              because of their endless possibilities.
            </p>
          </div>
          <div>
            <img
              src={julianAvatar}
              alt="Julián Neuss"
              style={styles.foundersAvatar}
            />
            <a target="_blank" href="https://www.linkedin.com/in/jneuss/" rel="noreferrer">
              <h2>
                Julián Neuss{" "}
                <IconButton aria-label="list icon" sx={styles.social}>
                  <FaLinkedin />
                </IconButton>
              </h2>
              <h4>
                CO-FOUNDER & CEO
              </h4>
            </a>
            <p>
              Software Engineer & crypto enthusiast. Extremely passionate for
              blockchain technology, artificial intelligence, gaming and
              education. Founded a software factory. Built a P2E game in
              Decentraland. Won several hackathons including J.P Morgan “Code
              for Good” 2019 & McDonald's Ideatón 2019. Lead Tech Trek, a
              student non-profit organization. Started learning Chinese in 2017.
              Active speaker on everything related to crypto since 2017.
            </p>
          </div>
        </Box>
        <ProfileCard items={teamData} />
      </Container>
    </Box>
  );
};

export default Team;

const styles = {
  box: {
    marginBottom: "60px",
  },
  container: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    overflow: "hidden",
  },
  foundersImg: {
    width: "100%",
    maxWidth: "800px",
  },
  foundersAvatar: {
    position: "absolute",
    right: "30px",
    top: "-30px",
    borderRadius: "50%",
    width: "110px",
    border: "3px solid #0d89ff",
    "@media (max-width: 490px)": {
      width: "80px",
      right: "20px",
    },
  },
  founders: {
    display: "flex",
    paddingBottom: "80px",
    gap: "15px",
    "@media (max-width: 810px)": {
      gap: 0,
      flexWrap: "wrap",
      paddingBottom: "20px",
    },
    div: {
      position: "relative",
      width: "50%",
      padding: "20px",
      borderRadius: "5px",
      background: "#ededed",
      boxShadow: "1px 1px 3px #d4d4d4",
      "@media (max-width: 810px)": {
        width: "100%",
        marginBottom: "30px",
        "&:first-of-type": {
          marginBottom: "60px",
        },
      },
    },
    a: {
      textDecoration: "none",
      color: "black",
      h2: {
        margin: "4px 0 4px 0"
      },
      h4: {
        margin: "0 0 4px 0"
      },
      "&:hover": {
        social: {
          color: "primary.main",
        },
        color: "primary.main",
      },
    },
    p: {
      margin: 0,
      width: "100%",
      textAlign: "justify",
      "@media (max-width: 810px)": {
        fontSize: "15px",
      },
    },
  },
  social: {
    width: "35px",
    height: "auto",
    padding: 0,
    fontSize: 15,
    flexShrink: 0,
    justifyContent: "flex-start",
    marginTop: "3px",
    position: "absolute",
    marginLeft: "5px",
    "@media screen and (max-width: 480px)": {
      width: "25px",
      fontSize: 18,
      mt: "5px",
    },
  },
};
