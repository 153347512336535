import React from "react";
import { Box, Container, Typography } from "@mui/material";
import BgShape from "../../assets/cta-shape-1.svg";
import btnShape from "../../assets/cta-btn-shape-1.svg";
import ComparisonTable from "../../components/ComparisonTable";
import fanzLogo from "../../assets/fanz-0.png";
import fanz1 from "../../assets/fanz-1.webp";
import fanz2 from "../../assets/fanz-2.webp";
import fanz3 from "../../assets/fanz-3.webp";
import fanz4 from "../../assets/fanz-4.webp";
import otherTicketingPlatforms from "../../assets/otras-ticketeras.png";
import ticketMaster1 from "../../assets/ticketmaster-1.webp";
import ticketMaster2 from "../../assets/ticketmaster-2.webp";
import ticketMaster3 from "../../assets/ticketmaster-3.webp";
import ticketMaster4 from "../../assets/ticketmaster-4.webp";

const ComparisonData = [
  {
    name: "ticketmaster",
    logo: otherTicketingPlatforms,
    bullets: [
      {
        icon: ticketMaster1,
        text: "Comisiones sobre el volumen",
      },
      {
        icon: ticketMaster3,
        text: "Opciones limitadas de marca blanca",
      },
      {
        icon: ticketMaster2,
        text: "Maneja los fondos por vos",
      },
      {
        icon: ticketMaster4,
        text: "Comisiones minimas por traer gente a la plataforma",
      },
    ],
    costs: "15-20%",
    unit: "por ticket vendido",
  },
  {
    separator: "O",
  },
  {
    name: "Fanz",
    logo: fanzLogo,
    bullets: [
      {
        icon: fanz1,
        text: "Tarifa de servicio razonable",
      },
      {
        icon: fanz4,
        text: "Sistema de promoción orgánica",
      },
      {
        icon: fanz2,
        text: "Reventas oficiales y controladas",
      },
      {
        icon: fanz3,
        text: "Eventos libres de fraude",
      },
    ],
    costs: "495 usd",
    unit: "por mes",
  },
];

const BrandComparison = () => {
  return (
    <Box sx={styles.wrapper}>
      <Container sx={styles.container}>
        <Typography as="h2" sx={styles.title}>
          Decime... ¿Con cuál te quedás?
        </Typography>
        <ComparisonTable ComparisonData={ComparisonData} />
      </Container>
    </Box>
  );
};

export default BrandComparison;

const styles = {
  wrapper: {
    margin: "0 auto",
    maxWidth: "1140px",
    marginBottom: "60px",
    "@media (max-width: 768px)": {
      marginBottom: 0,
    },
  },
  title: {
    paddingTop: "1em",
    textAlign: "center",
    margin: "0px",
    color: "black",
    fontSize: "38px",
    fontWeight: "700",
    marginBottom: ".5em",
  },
  subtitle: {
    paddingTop: 0,
    textAlign: "center",
    margin: "0px",
    color: "black",
    fontSize: "20px",
    fontWeight: "400",
    marginBottom: ".5em",
  },
  inner: {
    padding: ["45px 30px 50px", null, null, "45px 50px", null, "45px 70px"],
    mt: [0, null, null, null, "80px"],
    position: "relative",
    zIndex: "10",
    backgroundColor: "primary.main",
    backgroundImage: `url(${BgShape})`,
    backgroundRepeat: "no-repeat",
    backgroundPosition: "60% center",
    borderRadius: "8px",
    display: "flex",
    alignItems: "center",
    textAlign: ["center", null, null, "left"],
    flexDirection: ["column", null, null, "row"],
    justifyContent: ["center", null, null, "space-between"],
    h4: {
      color: "#fff",
    },
  },
  btn: {
    backgroundColor: "#fff",
    color: "primary.main",
    borderRadius: "5px",
    fontWeight: "700",
    letterSpacing: "0.1em",
    padding: ["10px 24px", null, null, null, null, "15px 44px"],
    position: "relative",
    outline: "none",
    webkitAppearance: "none",
    "&:before, &:after": {
      content: '""',
      position: "absolute",
      width: "73px",
      height: "26px",
      backgroundImage: `url(${btnShape})`,
      backgroundRepeat: "no-repeat",
      backgroundPosition: "center",
      bottom: "calc(100% + 10px)",
      left: "50%",
      transform: "translateX(-50%)",
    },
    "&:after": {
      bottom: "auto",
      top: "calc(100% + 10px)",
      transform: "translateX(-50%) rotate(180deg)",
    },
    "&:hover": {
      backgroundColor: "black",
      color: "#fff",
    },
  },
};
