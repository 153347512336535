import React from "react";
import { Box, Container } from "@mui/material";
import BlockTitle from "../../components/BlockTitle/BlockTitle";
import SEO from "../../components/SEO/SEO";

const RecursosLegales = () => {
  return (
    <Box id="terms" sx={styles.box}>
      <Box style={{ width: "100%", height: "100px", background: "#050327" }} />
      <SEO
        title="Fanz | Recursos Legales"
        description="Recursos legales de Fanz"
        image="%PUBLIC_URL%/og_image.jpg"
      />
      <Container sx={styles.container}>
        <div
          id="terminos-y-condiciones"
          style={{
            paddingTop: "60px",
            marginTop: "-60px",
            display: "block",
            visibility: "hidden",
            position: "relative",
          }}
        />
        <BlockTitle
          tagline="Last Updated: October 25, 2023"
          heading="Términos & Condiciones"
        />
        <Box sx={styles.terms}>
          <h2 className="c1">
            <span className="c0">VISIÓN GENERAL</span>
          </h2>
          <p className="c1 c2">
            <span className="c0" />
          </p>
          <p className="c1">
            <span className="c0">
              Este sitio web es operado por Fanz, Inc. En todo el sitio, los
              términos "nosotros", "nos" y "nuestro" se refieren a Fanz, Inc.
              Fanz, Inc. ofrece este sitio web, incluyendo toda la información,
              herramientas y servicios disponibles en este sitio, al usuario,
              condicionado a su aceptación de todos los términos, condiciones,
              políticas y avisos aquí declarados.
            </span>
          </p>
          <p className="c1 c2">
            <span className="c0" />
          </p>
          <p className="c1">
            <span className="c0">
              Al visitar nuestro sitio y/o comprar algo de nosotros, usted
              acepta participar en nuestro "Servicio" y acepta estar sujeto a
              los siguientes términos y condiciones ("Términos de servicio",
              "Términos"), incluyendo aquellos términos y condiciones y
              políticas adicionales aquí referenciados y/o disponibles mediante
              hipervínculo. Estos Términos de servicio se aplican a todos los
              usuarios del sitio, incluyendo, entre otros, a los usuarios que
              son navegadores, proveedores, clientes, comerciantes y/o
              contribuyentes de contenido.
            </span>
          </p>
          <p className="c1 c2">
            <span className="c0" />
          </p>
          <p className="c1">
            <span className="c0">
              Por favor, lea cuidadosamente estos Términos de servicio antes de
              acceder o utilizar nuestro sitio web. Al acceder o utilizar
              cualquier parte del sitio, usted acepta estar sujeto a estos
              Términos de servicio. Si no acepta todos los términos y
              condiciones de este acuerdo, entonces no puede acceder al sitio
              web ni utilizar ninguno de los servicios. Si estos Términos de
              servicio se consideran una oferta, la aceptación se limita
              expresamente a estos Términos de servicio.
            </span>
          </p>
          <p className="c1 c2">
            <span className="c0" />
          </p>
          <p className="c1">
            <span className="c0">
              Cualquier nueva función o herramienta que se agregue a la tienda
              actual también estará sujeta a los Términos de servicio. Puede
              revisar la versión más actualizada de los Términos de servicio en
              cualquier momento en esta página. Nos reservamos el derecho de
              actualizar, cambiar o reemplazar cualquier parte de estos Términos
              de servicio mediante la publicación de actualizaciones y/o cambios
              en nuestro sitio web. Es su responsabilidad revisar esta página
              periódicamente para conocer los cambios. El uso continuo o el
              acceso al sitio web después de la publicación de cualquier cambio
              constituye la aceptación de dichos cambios.
            </span>
          </p>
          <p className="c1 c2">
            <span className="c0" />
          </p>
          <h2 className="c1">
            <span className="c0">SECCIÓN 1 - TÉRMINOS DE FANZ.EVENTS</span>
          </h2>
          <p className="c1 c2">
            <span className="c0" />
          </p>
          <p className="c1">
            <span className="c0">
              Al aceptar estos Términos de servicio, usted declara que tiene al
              menos la mayoría de edad en su estado o provincia de residencia, o
              que tiene la mayoría de edad en su estado o provincia de
              residencia y nos ha dado su consentimiento para permitir que
              cualquiera de sus dependientes menores utilice este sitio.
            </span>
          </p>
          <p className="c1 c2">
            <span className="c0" />
          </p>
          <p className="c1">
            <span className="c0">
              No puede utilizar nuestros productos para ningún propósito ilegal,
              inmoral o no autorizado, ni puede, en el uso del Servicio, violar
              ninguna ley en su jurisdicción (incluyendo, entre otras, las leyes
              de copyright).
            </span>
          </p>
          <p className="c1 c2">
            <span className="c0" />
          </p>
          <p className="c1">
            <span className="c0">
              No debe transmitir gusanos informáticos, virus o cualquier código
              de naturaleza destructiva.
            </span>
          </p>
          <p className="c1 c2">
            <span className="c0" />
          </p>
          <p className="c1">
            <span className="c0">
              El incumplimiento o violación de cualquiera de los Términos
              resultará en la terminación inmediata de nuestros Servicios hacia
              usted.
            </span>
          </p>
          <p className="c1 c2">
            <span className="c0" />
          </p>
          <h2 className="c1">
            <span className="c0">SECCIÓN 2 - CONDICIONES GENERALES</span>
          </h2>
          <p className="c1 c2">
            <span className="c0" />
          </p>
          <p className="c1">
            <span className="c0">
              Nos reservamos el derecho de rechazar el servicio a cualquier
              persona por cualquier motivo en cualquier momento.
            </span>
          </p>
          <p className="c1 c2">
            <span className="c0" />
          </p>
          <p className="c1">
            <span className="c0">
              Usted entiende que su contenido (sin incluir la información de la
              tarjeta de crédito) puede ser transferido sin cifrar e implicar
              (a) transmisiones a través de varias redes; y (b) cambios para
              adaptarse a los requisitos técnicos de las redes o dispositivos de
              conexión. La información de la tarjeta de crédito siempre está
              cifrada durante la transferencia a través de las redes.
            </span>
          </p>
          <p className="c1 c2">
            <span className="c0" />
          </p>
          <p className="c1">
            <span className="c0">
              Usted acepta no reproducir, duplicar, copiar, vender, revender ni
              explotar ninguna parte del Servicio, uso del Servicio o acceso al
              Servicio o cualquier contacto en el sitio web a través del cual se
              proporciona el servicio, sin el permiso expreso por escrito de
              nuestra parte.
            </span>
          </p>
          <p className="c1 c2">
            <span className="c0" />
          </p>
          <p className="c1">
            <span className="c0">
              Los encabezados utilizados en este acuerdo se incluyen sólo por
              conveniencia y no limitarán ni afectarán de otra manera estos
              Términos.
            </span>
          </p>
          <p className="c1 c2">
            <span className="c0" />
          </p>
          <h2 className="c1">
            <span className="c0">
              SECCIÓN 3 - EXACTITUD, INTEGRIDAD Y PUNTUALIDAD DE LA INFORMACIÓN
            </span>
          </h2>
          <p className="c1 c2">
            <span className="c0" />
          </p>
          <p className="c1">
            <span className="c0">
              No somos responsables si la información disponible en este sitio
              no es precisa, completa o actual. El material en este sitio se
              proporciona "tal cual", solo para información general y no debe
              ser considerado como la única base para tomar decisiones sin
              consultar fuentes de información primarias, más precisas,
              completas o más oportunas. Cualquier confianza en el material en
              este sitio es bajo su propio riesgo y no seremos responsables por
              dicha confianza o uso de cualquier información en el sitio o en
              los servicios.
            </span>
          </p>
          <p className="c1 c2">
            <span className="c0" />
          </p>
          <p className="c1">
            <span className="c0">
              En particular, ciertos aspectos o secciones de nuestro sitio en
              relación con los eventos listados, incluyendo la fecha del evento,
              lugar, elenco de artistas, precios, promociones, listados, ofertas
              o cualquier información, han sido cargados o se basan en las
              representaciones del respectivo organizador tercero del evento.
              Usted acepta que en ningún caso seremos responsables o
              responsables por su confianza en o uso de dicha información, ni se
              nos requerirá evaluar, verificar o investigar de manera
              independiente la precisión o veracidad de cualquier evento o
              información listada en el sitio.
            </span>
          </p>
          <p className="c1 c2">
            <span className="c0" />
          </p>
          <p className="c1">
            <span className="c0">
              Este sitio puede contener cierta información histórica. La
              información histórica, necesariamente, no es actual y se
              proporciona solo como referencia. Nos reservamos el derecho de
              modificar el contenido de este sitio en cualquier momento, pero no
              tenemos la obligación de actualizar ninguna información en nuestro
              sitio. Usted acepta que es su responsabilidad monitorear los
              cambios en nuestro sitio.
            </span>
          </p>
          <p className="c1 c2">
            <span className="c0" />
          </p>
          <h2 className="c1">
            <span className="c0">
              SECCIÓN 4 - MODIFICACIONES AL SERVICIO Y PRECIOS
            </span>
          </h2>
          <p className="c1 c2">
            <span className="c0" />
          </p>
          <p className="c1">
            <span className="c0">
              Los precios de nuestros productos están sujetos a cambios sin
              previo aviso.
            </span>
          </p>
          <p className="c1 c2">
            <span className="c0" />
          </p>
          <p className="c1">
            <span className="c0">
              Nos reservamos el derecho en cualquier momento de modificar o
              descontinuar el Servicio (o cualquier parte o contenido del mismo)
              sin previo aviso en cualquier momento.
            </span>
          </p>
          <p className="c1 c2">
            <span className="c0" />
          </p>
          <p className="c1">
            <span className="c0">
              No seremos responsables ante usted o ante cualquier tercero por
              cualquier modificación, cambio de precio, suspensión o
              discontinuidad del Servicio.
            </span>
          </p>
          <p className="c1 c2">
            <span className="c0" />
          </p>
          <h2 className="c1">
            <span className="c0">
              Sección 5 - Exactitud de la Información de Facturación y Cuenta
            </span>
          </h2>
          <p className="c1 c2">
            <span className="c0" />
          </p>
          <p className="c1">
            <span className="c0">
              Nos reservamos el derecho de rechazar cualquier pedido que realice
              con nosotros. Podemos, a nuestra sola discreción, limitar o
              cancelar cantidades compradas por persona, por hogar o por pedido.
              Estas restricciones pueden incluir pedidos realizados por o bajo
              la misma cuenta de cliente, la misma tarjeta de crédito y/o
              pedidos que utilicen la misma dirección de facturación y/o envío.
              En caso de que realicemos un cambio o cancelemos un pedido,
              podemos intentar notificarle contactándolo en la dirección de
              correo electrónico y/o en la dirección de facturación/número de
              teléfono proporcionados en el momento en que se realizó el pedido.
              Nos reservamos el derecho de limitar o prohibir pedidos que, a
              nuestro criterio exclusivo, parezcan ser realizados por
              distribuidores, revendedores o distribuidores.
            </span>
          </p>
          <p className="c1 c2">
            <span className="c0" />
          </p>
          <p className="c1">
            <span className="c0">
              Usted acepta proporcionar información de compra y de cuenta
              actual, completa y precisa para todas las compras realizadas en
              nuestra tienda. Usted acepta actualizar su cuenta y otra
              información, incluyendo su dirección de correo electrónico y
              números de tarjeta de crédito y fechas de vencimiento, para que
              podamos completar sus transacciones y contactarlo según sea
              necesario.
            </span>
          </p>
          <p className="c1 c2">
            <span className="c0" />
          </p>
          <p className="c1">
            <span className="c0">
              Usted acepta que se le puede negar la entrada a cualquier evento
              si su boleto comprado contiene cualquier nombre o información
              incorrecta, o no coincide con ninguna identificación creíble que
              pueda proporcionar.
            </span>
          </p>
          <p className="c1 c2">
            <span className="c0" />
          </p>
          <h2 className="c1">
            <span className="c0">Sección 6 - Enlaces a Terceros</span>
          </h2>
          <p className="c1 c2">
            <span className="c0" />
          </p>
          <p className="c1">
            <span className="c0">
              Ciertos contenidos, productos y servicios disponibles a través de
              nuestro Servicio pueden incluir materiales de terceros.
            </span>
          </p>
          <p className="c1 c2">
            <span className="c0" />
          </p>
          <p className="c1">
            <span className="c0">
              Los enlaces de terceros en este sitio pueden dirigirlo a sitios
              web de terceros que no están afiliados con nosotros. No somos
              responsables de examinar o evaluar el contenido o la exactitud y
              no garantizamos ni tendremos ninguna responsabilidad por cualquier
              material o sitio web de terceros, o por cualquier otro material,
              producto o servicio de terceros.
            </span>
          </p>
          <p className="c1 c2">
            <span className="c0" />
          </p>
          <p className="c1">
            <span className="c0">
              No somos responsables de ningún daño o perjuicio relacionado con
              la compra o el uso de bienes, servicios, recursos, contenido o
              cualquier otra transacción realizada en conexión con cualquier
              sitio web de terceros. Por favor, revise cuidadosamente las
              políticas y prácticas de terceros y asegúrese de entenderlas antes
              de participar en cualquier transacción. Las quejas, reclamaciones,
              preocupaciones o preguntas relacionadas con productos de terceros
              deben dirigirse al tercero.
            </span>
          </p>
          <p className="c1 c2">
            <span className="c0" />
          </p>
          <h2 className="c1">
            <span className="c0">
              SECCIÓN 7 - COMENTARIOS DEL USUARIO, OPINIONES Y OTRAS
              PRESENTACIONES
            </span>
          </h2>
          <p className="c1 c2">
            <span className="c0" />
          </p>
          <p className="c1">
            <span className="c0">
              Si, a nuestra solicitud, envías ciertas presentaciones específicas
              (por ejemplo, entradas para concursos) o, sin una solicitud de
              nuestra parte, envías ideas creativas, sugerencias, propuestas,
              planes u otros materiales, ya sea en línea, por correo
              electrónico, por correo postal o de otra manera (colectivamente,
              "comentarios"), aceptas que en cualquier momento podamos, sin
              restricción alguna, editar, copiar, publicar, distribuir, traducir
              y usar de cualquier otra manera en cualquier medio los comentarios
              que nos envíes. No tenemos la obligación (1) de mantener cualquier
              comentario en confidencialidad; (2) de pagar compensación por
              cualquier comentario; o (3) de responder a cualquier comentario.
            </span>
          </p>
          <p className="c1 c2">
            <span className="c0" />
          </p>
          <p className="c1">
            <span className="c0">
              Podemos, pero no tenemos la obligación de monitorear, editar o
              eliminar contenido que determinemos a nuestro exclusivo criterio
              que es ilegal, ofensivo, amenazante, difamatorio, pornográfico,
              obsceno u objetable de cualquier otra manera, o que viola
              cualquier propiedad intelectual de una parte o estos Términos de
              Servicio.
            </span>
          </p>
          <p className="c1 c2">
            <span className="c0" />
          </p>
          <p className="c1">
            <span className="c0">
              Aceptas que tus comentarios no violarán ningún derecho de
              terceros, incluyendo derechos de autor, marca registrada,
              privacidad, personalidad u otro derecho personal o de propiedad.
              Además, aceptas que tus comentarios no contendrán material
              difamatorio o ilegal, abusivo u obsceno, ni contendrán virus
              informáticos u otro malware que pueda afectar de alguna manera el
              funcionamiento del Servicio o cualquier sitio web relacionado. No
              debes utilizar una dirección de correo electrónico falsa,
              pretender ser alguien que no eres o engañarnos o a terceros sobre
              el origen de cualquier comentario. Eres el único responsable de
              cualquier comentario que hagas y de su precisión. No asumimos
              ninguna responsabilidad y no nos hacemos responsables por ningún
              comentario publicado por ti o cualquier tercero.
            </span>
          </p>
          <p className="c1 c2">
            <span className="c0" />
          </p>
          <h2 className="c1">
            <span className="c0">SECCIÓN 8 - INFORMACIÓN PERSONAL</span>
          </h2>
          <p className="c1 c2">
            <span className="c0" />
          </p>
          <p className="c1">
            <span className="c0">
              El envío de información personal a través de la tienda está regido
              por nuestra Política de privacidad.
            </span>
          </p>
          <p className="c1 c2">
            <span className="c0" />
          </p>
          <h2 className="c1">
            <span className="c0">
              SECCIÓN 9 - ERRORES, INEXACTITUDES Y OMISIONES
            </span>
          </h2>
          <p className="c1 c2">
            <span className="c0" />
          </p>
          <p className="c1">
            <span className="c0">
              En ocasiones, puede haber información en nuestro sitio o en el
              Servicio que contenga errores tipográficos, inexactitudes u
              omisiones que puedan referirse a descripciones de productos,
              precios, promociones, ofertas, cargos de envío de productos,
              tiempos de tránsito y disponibilidad. Nos reservamos el derecho de
              corregir cualquier error, inexactitud u omisión, y de cambiar o
              actualizar información o cancelar pedidos si cualquier información
              en el Servicio o en cualquier sitio web relacionado es inexacta en
              cualquier momento sin previo aviso (incluyendo después de que haya
              enviado su pedido).
            </span>
          </p>
          <p className="c1 c2">
            <span className="c0" />
          </p>
          <p className="c1">
            <span className="c0">
              No nos comprometemos a actualizar, modificar o aclarar información
              en el Servicio o en cualquier sitio web relacionado, incluyendo,
              sin limitación, información de precios, excepto según lo requiera
              la ley. Ninguna fecha de actualización o de refresco especificada
              aplicada en el Servicio o en cualquier sitio web relacionado debe
              tomarse como indicación de que toda la información en el Servicio
              o en cualquier sitio web relacionado ha sido modificada o
              actualizada.
            </span>
          </p>
          <p className="c1 c2">
            <span className="c0" />
          </p>
          <h2 className="c1">
            <span className="c0">SECCIÓN 10 - USOS PROHIBIDOS</span>
          </h2>
          <p className="c1 c2">
            <span className="c0" />
          </p>
          <p className="c1">
            <span className="c0">
              Además de otras prohibiciones establecidas en los Términos de
              Servicio, se le prohíbe usar el sitio o su contenido: (a) para
              cualquier propósito ilegal; (b) para solicitar a otros que
              realicen o participen en actos ilícitos; (c) para violar cualquier
              reglamento, norma, ley o ordenanza internacional, federal,
              provincial o estatal o local; (d) para infringir o violar nuestros
              derechos de propiedad intelectual o los derechos de propiedad
              intelectual de otros; (e) para acosar, abusar, insultar, dañar,
              difamar, calumniar, desacreditar, intimidar o discriminar por
              género, orientación sexual, religión, etnia, raza, edad, origen
              nacional o discapacidad; (f) para enviar información falsa o
              engañosa; (g) para cargar o transmitir virus o cualquier otro tipo
              de código malicioso que se utilizará o puede ser utilizado de
              cualquier manera que afecte la funcionalidad o el funcionamiento
              del Servicio o de cualquier sitio web relacionado, otros sitios
              web o Internet; (h) para recopilar o rastrear la información
              personal de otros; (i) para hacer spam, pescar, hacer pharming,
              pretextar, arañar o rascar; (j) para cualquier propósito obsceno o
              inmoral; o (k) para interferir o eludir las características de
              seguridad del Servicio o de cualquier sitio web relacionado, otros
              sitios web o Internet. Nos reservamos el derecho de terminar su
              uso del Servicio o de cualquier sitio web relacionado por violar
              cualquiera de los usos prohibidos.
            </span>
          </p>
          <p className="c1 c2">
            <span className="c0" />
          </p>
          <h2 className="c1">
            <span className="c0">
              SECCIÓN 11 - RENUNCIA DE GARANTÍAS; LIMITACIÓN DE RESPONSABILIDAD
            </span>
          </h2>
          <p className="c1 c2">
            <span className="c0" />
          </p>
          <p className="c1">
            <span className="c0">
              No garantizamos, representamos o aseguramos que el uso de nuestro
              servicio será ininterrumpido, oportuno, seguro o libre de errores.
            </span>
          </p>
          <p className="c1 c2">
            <span className="c0" />
          </p>
          <p className="c1">
            <span className="c0">
              No garantizamos que los resultados que se puedan obtener del uso
              del servicio sean precisos o confiables.
            </span>
          </p>
          <p className="c1 c2">
            <span className="c0" />
          </p>
          <p className="c1">
            <span className="c0">
              Usted acepta que de vez en cuando podemos eliminar el servicio por
              períodos de tiempo indefinidos o cancelar el servicio en cualquier
              momento, sin previo aviso.
            </span>
          </p>
          <p className="c1 c2">
            <span className="c0" />
          </p>
          <p className="c1">
            <span className="c0">
              Expresamente acepta que el uso del servicio, o la incapacidad de
              usarlo, es bajo su propio riesgo. El servicio y todos los
              productos y servicios entregados a través del servicio están
              (excepto como expresamente declarado por nosotros) proporcionados
              'tal cual' y 'según estén disponibles' para su uso, sin ninguna
              representación, garantía o condición de ningún tipo, ya sea
              expresa o implícita, incluyendo todas las garantías o condiciones
              implícitas de comercialización, calidad mercantil, idoneidad para
              un propósito particular, durabilidad, título y no infracción.
            </span>
          </p>
          <p className="c1 c2">
            <span className="c0" />
          </p>
          <p className="c1">
            <span className="c0">
              En particular, usted acepta que los eventos listados en el sitio
              son responsabilidad exclusiva de sus respectivos organizadores de
              terceros que utilizan la plataforma Fanz, incluyendo la fecha del
              evento, el lugar, la lista de artistas, los precios, promociones,
              listas, ofertas, reembolsos o cualquier otro asunto relacionado:
              acepta que en ningún caso seremos responsables o responsables por
              su confianza, uso, asistencia, participación o cualquier
              circunstancia con respecto a dichos eventos, incluyendo
              cancelaciones o aplazamientos de eventos, ni estaremos obligados a
              evaluar, verificar o investigar de forma independiente la
              exactitud o veracidad de tales eventos.
            </span>
          </p>
          <p className="c1 c2">
            <span className="c0" />
          </p>
          <p className="c1">
            <span className="c0">
              Reconoce que Fanz no es responsable ni se hace responsable de la
              gestión o ejecución real de ningún evento listado por promotores
              de terceros en el sitio o los servicios, y como tal, cualquier
              solicitud de reembolso o compensación en relación con
              cancelaciones de eventos, aplazamientos o incumplimientos será
              responsabilidad exclusiva del organizador del evento. Tampoco
              seremos responsables si el evento se niega a honrar su entrada
              comprada debido a inexactitudes, discrepancias, seguridad o por
              cualquier otra razón.
            </span>
          </p>
          <p className="c1 c2">
            <span className="c0" />
          </p>
          <p className="c1">
            <span className="c0">
              No se ofrecen posibles reembolsos, créditos o intercambios en
              ninguna entrada. Las tarifas de Fanz no son reembolsables bajo
              ninguna circunstancia.
            </span>
          </p>
          <p className="c1 c2">
            <span className="c0" />
          </p>
          <p className="c1">
            <span className="c0">
              Incluso en el caso de que expresemos o representemos que hemos
              realizado una verificación (o acciones similares o análogas) con
              respecto a cualquier evento, oferta o información en nuestro sitio
              o servicios, simplemente significa que hemos emprendido la acción
              mencionada y nada más: acepta que Fanz no ofrece garantías y no
              será responsable por ningún asunto con respecto a cualquier
              información o evento listado en su sitio o servicios.
            </span>
          </p>
          <p className="c1 c2">
            <span className="c0" />
          </p>
          <p className="c1">
            <span className="c0">
              En ningún caso Fanz, Inc., nuestros directores, oficiales,
              empleados, afiliados, agentes, contratistas, pasantes, proveedores
              de servicios o licenciantes serán responsables por cualquier
              lesión, pérdida, reclamo o daño directo, indirecto, incidental,
              punitivo, especial o consecuente de cualquier tipo, incluyendo,
              sin limitación, la pérdida de ganancias, ingresos perdidos,
              ahorros perdidos, pérdida de datos, costos de reemplazo o
              cualquier otro daño similar, ya sea basado en contrato, agravio
              (incluyendo negligencia), responsabilidad estricta o de otra
              manera, que surja de su uso del sitio, del servicio o de cualquier
              producto adquirido a través del servicio, o por cualquier otro
              reclamo relacionado de cualquier manera con su uso del sitio, del
              servicio o de cualquier producto, incluyendo, pero no limitado a,
              cualquier error u omisión en cualquier contenido, o cualquier
              pérdida o daño de cualquier tipo incurrido como resultado del uso
              del sitio o del servicio o cualquier contenido (o producto)
              publicado, transmitido o puesto a disposición de otra manera a
              través del servicio, incluso si se le ha informado de su
              posibilidad. Debido a que algunos estados o jurisdicciones no
              permiten la exclusión o la limitación de responsabilidad por daños
              consecuentes o incidentales, en dichos estados o jurisdicciones,
              nuestra responsabilidad se limitará en la medida máxima permitida
              por la ley.
            </span>
          </p>
          <p className="c1 c2">
            <span className="c0" />
          </p>
          <h2 className="c1">
            <span className="c0">SECCIÓN 12 - INDEMNIZACIÓN</span>
          </h2>
          <p className="c1 c2">
            <span className="c0" />
          </p>
          <p className="c1">
            <span className="c0">
              Usted acepta indemnizar, defender y eximir de responsabilidad a
              Fanz y a nuestra empresa matriz, filiales, afiliados, socios,
              funcionarios, directores, agentes, contratistas, licenciantes,
              proveedores de servicios, subcontratistas, proveedores, pasantes y
              empleados, de cualquier reclamo o demanda, incluyendo honorarios
              razonables de abogados, realizados por un tercero debido a o
              surgidos de su incumplimiento de estos Términos de Servicio o de
              los documentos que incorporan por referencia, o su violación de
              cualquier ley o de los derechos de un tercero.
            </span>
          </p>
          <p className="c1 c2">
            <span className="c0" />
          </p>
          <h2 className="c1">
            <span className="c0">SECCIÓN 13 - DIVISIBILIDAD</span>
          </h2>
          <p className="c1 c2">
            <span className="c0" />
          </p>
          <p className="c1">
            <span className="c0">
              En caso de que alguna disposición de estos Términos de Servicio
              sea determinada como ilegal, nula o inexequible, dicha disposición
              será automáticamente modificada en la medida máxima permitida por
              la ley aplicable para hacerla ejecutable, y la porción inaplicable
              se considerará separada de estos Términos de Servicio. Esta
              determinación no afectará la validez y ejecutabilidad de las
              disposiciones restantes.
            </span>
          </p>
          <p className="c1 c2">
            <span className="c0" />
          </p>
          <h2 className="c1">
            <span className="c0">SECCIÓN 14 - TERMINACIÓN</span>
          </h2>
          <p className="c1 c2">
            <span className="c0" />
          </p>
          <p className="c1">
            <span className="c0">
              Las obligaciones y responsabilidades de las partes incurridas
              antes de la fecha de terminación sobrevivirán a la terminación de
              este acuerdo para todos los fines.
            </span>
          </p>
          <p className="c1 c2">
            <span className="c0" />
          </p>
          <p className="c1">
            <span className="c0">
              Estos Términos de Servicio son efectivos a menos que sean
              terminados por usted o por nosotros. Usted puede terminar estos
              Términos de Servicio en cualquier momento notificándonos que ya no
              desea utilizar nuestros servicios o cuando deje de usar nuestro
              sitio.
            </span>
          </p>
          <p className="c1 c2">
            <span className="c0" />
          </p>
          <p className="c1">
            <span className="c0">
              Si, en nuestro criterio exclusivo, usted no cumple, o sospechamos
              que no ha cumplido, con algún término o disposición de estos
              Términos de Servicio, también podemos terminar este acuerdo en
              cualquier momento sin previo aviso y usted seguirá siendo
              responsable de todas las cantidades adeudadas hasta la fecha de
              terminación; y / o podemos negarle el acceso a nuestros servicios
              (o cualquier parte de ellos) en consecuencia.
            </span>
          </p>
          <p className="c1 c2">
            <span className="c0" />
          </p>
          <h2 className="c1">
            <span className="c0">SECCIÓN 15 - ACUERDO COMPLETO</span>
          </h2>
          <p className="c1 c2">
            <span className="c0" />
          </p>
          <p className="c1">
            <span className="c0">
              El hecho de que nosotros no ejerzamos o hagamos valer ningún
              derecho o disposición de estos Términos de Servicio no constituirá
              una renuncia a dicho derecho o disposición. Estos Términos de
              Servicio y cualquier política o norma de funcionamiento publicada
              por nosotros en este sitio o en relación con el Servicio
              constituyen el acuerdo completo y el entendimiento entre usted y
              nosotros, y rigen su uso del Servicio, reemplazando cualquier
              acuerdo, comunicación y propuesta previos o contemporáneos, ya sea
              oral o escrito, entre usted y nosotros (incluyendo, pero sin
              limitarse a, cualquier versión anterior de los Términos de
              Servicio), excepto en el caso de los organizadores de eventos que
              han ejecutado un contrato escrito separado con Fanz donde estos
              Términos se considerarán incorporados en dicho contrato/s
              separado/s, y excepto en el caso de nuestras subpolíticas donde
              estos Términos se considerarán incorporados, incluida la Política
              de Reembolso de Fanz, la Política de Reventa, Términos de Venta
              (en caso de conflicto entre estos Términos de Servicio y las
              subpolíticas mencionadas, prevalecerán estos Términos).
            </span>
          </p>
          <p className="c1 c2">
            <span className="c0" />
          </p>
          <p className="c1">
            <span className="c0">
              Cualquier ambigüedad en la interpretación de estos Términos de
              Servicio no se interpretará en contra de la parte redactora.
            </span>
          </p>
          <p className="c1 c2">
            <span className="c0" />
          </p>
          <h2 className="c1">
            <span className="c0">SECCIÓN 16 - FUERZA MAYOR</span>
          </h2>
          <p className="c1 c2">
            <span className="c0" />
          </p>
          <p className="c1">
            <span className="c0">
              Fanz no será responsable si el cumplimiento de sus obligaciones se
              ve impedido o limitado de alguna manera por cualquier acto o
              evento relacionado con cualquier Fuerza Mayor. Fuerza mayor
              significa eventos imprevistos más allá del control razonable de
              Fanz, incluyendo, pero no limitado a, actos de Dios, actos de la
              naturaleza, actos de gobiernos, guerra, pandemia, enfermedad,
              mareas, rayos, inundaciones, explosiones, embargo, apagones,
              bloqueos, desaceleraciones, huelgas, sequías, rebelión,
              revolución, terrorismo.
            </span>
          </p>
          <p className="c1 c2">
            <span className="c0" />
          </p>
          <h2 className="c1">
            <span className="c0">SECCIÓN 17 - LEY APLICABLE</span>
          </h2>
          <p className="c1 c2">
            <span className="c0" />
          </p>
          <p className="c1">
            <span className="c0">
              Estos Términos de Servicio y cualquier acuerdo separado en el que
              proporcionemos servicios estarán regidos y se interpretarán de
              acuerdo con las leyes del Estado de California en los Estados
              Unidos de América.
            </span>
          </p>
          <p className="c1 c2">
            <span className="c0" />
          </p>
          <h2 className="c1">
            <span className="c0">
              SECCIÓN 18 - CAMBIOS A LOS TÉRMINOS DE SERVICIO
            </span>
          </h2>
          <p className="c1 c2">
            <span className="c0" />
          </p>
          <p className="c1">
            <span className="c0">
              Puede revisar la versión más actual de los Términos de Servicio en
              cualquier momento en esta página.
            </span>
          </p>
          <p className="c1 c2">
            <span className="c0" />
          </p>
          <p className="c1">
            <span className="c0">
              Nos reservamos el derecho, a nuestra sola discreción, de
              actualizar, cambiar o reemplazar cualquier parte de estos Términos
              de Servicio publicando actualizaciones y cambios en nuestro sitio
              web. Es su responsabilidad revisar nuestro sitio web
              periódicamente para ver si hay cambios. El uso continuado o acceso
              a nuestro sitio web o al Servicio después de la publicación de
              cualquier cambio en estos Términos de Servicio constituye la
              aceptación de esos cambios.
            </span>
          </p>
          <p className="c1 c2">
            <span className="c0" />
          </p>
          <h2 className="c1">
            <span className="c0">SECCIÓN 19 - INFORMACIÓN DE CONTACTO</span>
          </h2>
          <p className="c1 c2">
            <span className="c0" />
          </p>
          <p className="c1">
            <span className="c0">
              Las preguntas sobre los Términos de Servicio deben enviarse a
              info@fanz.events.
            </span>
          </p>
        </Box>
        <div
          id="politicas-de-privacidad"
          style={{
            paddingTop: "60px",
            marginTop: "-60px",
            display: "block",
            visibility: "hidden",
            position: "relative",
          }}
        />
        <BlockTitle
          tagline="Last Updated: October 25, 2023"
          heading="Políticas de Privacidad"
        />
        <Box sx={styles.terms}>
          <h2>
            <span>Clases de Datos recogidos</span>
          </h2>
          <p>
            <span>
              Entre las clases de Datos Personales que recoge Fanz, ya sea
              directamente o a través de terceros, se encuentran: Cookies; Datos
              de uso; dirección de correo electrónico; contraseña; país; estado;
              provincia; ciudad; Datos comunicados durante el uso del servicio;
              posición geográfica; cantidad de Usuarios; información del
              dispositivo; estadísticas de sesión; latitud (de la ciudad);
              longitud (de la ciudad); información del navegador.
            </span>
          </p>
          <p>
            <span />
          </p>
          <p>
            <span>
              La información completa referente a cada categoría de Datos
              Personales que se recogen se proporciona en las secciones de la
              presente política de privacidad dedicadas a tal fin o mediante
              textos explicativos específicos que se muestran antes de la
              recogida de dichos Datos.
            </span>
          </p>
          <p>
            <span>
              Los Datos Personales podrán ser proporcionados libremente por el
              Usuario o, en caso de los Datos de Uso, serán recogidos
              automáticamente cuando se utilice Fanz.
            </span>
          </p>
          <p>
            <span>
              Todos los Datos solicitados por Fanz son obligatorios y la
              negativa a proporcionarlos podrá imposibilitar que Fanz pueda
              proceder a la prestación del Servicio. En los casos en los que
              Fanz indique específicamente que ciertos Datos no son
              obligatorios, los Usuarios serán libres de no comunicar tales
              Datos sin que esto tenga consecuencia alguna sobre la
              disponibilidad o el funcionamiento del Servicio. Los Usuarios que
              tengan dudas sobre qué Datos son obligatorios pueden contactar con
              el Titular.
            </span>
          </p>
          <p>
            <span>
              El uso de Cookies - o de otras herramientas de seguimiento - por
              parte de Fanz o por los titulares de servicios de terceros
              utilizados por Fanz tiene como finalidad la prestación del
              Servicio solicitado por el Usuario, además de cualesquiera otras
              finalidades que se describan en el presente documento y en la
              Política de Cookies, en caso de estar disponible.
            </span>
          </p>
          <p>
            <span />
          </p>
          <p>
            <span>
              El Usuario asume la responsabilidad respecto de los Datos
              Personales de terceros que se obtengan, publiquen o compartan a
              través de Fanz y declara por la presente que tiene el
              consentimiento de dichos terceros para proporcionar dichos Datos
              al Titular.
            </span>
          </p>
          <p>
            <span />
          </p>
          <h2 id="h.ly2nhl3plfvv">
            <span>Titular y Responsable del tratamiento de los Datos</span>
          </h2>
          <p>
            <span>
              Fanz, Inc., Palm Grove House, P.O.Box 438, Road Town, Tortola,
              VG1110, British Virgin Islands.
            </span>
          </p>
          <p className="c13">
            <span>Correo electrónico de contacto del Titular:</span>
            <span>&nbsp;info@fanz.events</span>
          </p>
          <p>
            <span />
          </p>
          <h2>
            <span>Los derechos de los Usuarios</span>
          </h2>
          <p>
            <span>
              Los Usuarios podrán ejercer ciertos derechos con respecto al
              tratamiento de Datos por parte del Titular.
            </span>
          </p>
          <p className="c13">
            <span>
              Los Usuarios con estándares de protección más elevados podrán
              ejercer cualquiera de los derechos detallados a continuación. En
              todos los demás supuestos, los Usuarios podrán preguntar al
              Titular para averiguar qué derechos les son de aplicación.
            </span>
          </p>
          <p className="c13">
            <span>
              Especialmente, los Usuarios tienen derecho a hacer lo siguiente:
            </span>
          </p>
          <ul className="c14 lst-kix_im30wqqpy1ck-0 start">
            <li className="c8 li-bullet-0">
              <span>Retirar su consentimiento en cualquier momento.</span>
              <span>
                &nbsp;Los Usuarios tienen derecho a retirar su consentimiento
                cuando lo hubieran concedido con anterioridad para el
                tratamiento de sus Datos Personales.
              </span>
            </li>
            <li className="c8 li-bullet-0">
              <span>Objeción al tratamiento de sus Datos.</span>
              <span>
                &nbsp;Los Usuarios tienen derecho a oponerse al tratamiento de
                sus Datos si dicho tratamiento se lleva a cabo con arreglo a una
                base jurídica distinta del consentimiento. Para más información
                pueden dirigirse a la sección correspondiente más adelante.
              </span>
            </li>
            <li className="c8 li-bullet-0">
              <span>Acceso a sus Datos.</span>
              <span>
                &nbsp;Los Usuarios tienen derecho a conocer si sus Datos serán
                tratados por el Titular, a obtener información sobre ciertos
                aspectos del tratamiento, además de obtener una copia de los
                Datos objeto del tratamiento.
              </span>
            </li>
            <li className="c8 li-bullet-0">
              <span>Verificar y solicitar la modificación.</span>
              <span>
                &nbsp;Los Usuarios tienen derecho a verificar la exactitud de
                sus Datos y solicitar que los mismos se actualicen o corrijan.
              </span>
            </li>
            <li className="c8 li-bullet-0">
              <span>Restringir el tratamiento de sus Datos.</span>
              <span>
                &nbsp;Los Usuarios tienen derecho, en ciertos supuestos, a
                restringir el tratamiento de sus Datos. En ese supuesto, el
                Titular procesará sus Datos con el único propósito de
                almacenarlos.
              </span>
            </li>
            <li className="c8 li-bullet-0">
              <span>Borrar o eliminar los Datos Personales.</span>
              <span>
                &nbsp;Los Usuarios tienen derecho, en ciertos supuestos, a
                obtener la eliminación de sus Datos por parte del Titular.
              </span>
            </li>
            <li className="c8 li-bullet-0">
              <span>Recibir sus Datos y transferirlos a otro responsable.</span>
              <span>
                &nbsp;Los Usuarios tienen derecho a recibir sus Datos en un
                formato estándar, estructurado, mecánicamente legible y, si
                fuera técnicamente posible, a que se dé traslado de los mismos a
                otro responsable sin ningún impedimento. Esta provisión será de
                aplicación siempre que los Datos se hayan tratado a través de
                medios automatizados y que el tratamiento se base en el
                consentimiento del Usuario, en un contrato del que el Usuario
                forme parte o que aparezca en las obligaciones precontractuales
                del mismo.
              </span>
            </li>
            <li className="c8 li-bullet-0">
              <span>Poner una reclamación.</span>
              <span>
                &nbsp;Los Usuarios tienen derecho a poner una reclamación ante
                la autoridad competente en materia de protección de datos de
                carácter personal.
              </span>
            </li>
          </ul>
          <h3 className="c11" id="h.t9nl2yqbfglo">
            <span className="c0">
              Detalles sobre el derecho de oposición al tratamiento
            </span>
          </h3>
          <p className="c7">
            <span>
              Cuando el tratamiento de los Datos Personales sea de interés
              público, en el ejercicio de competencias oficiales otorgadas al
              Titular o con motivo de un interés legítimo del Titular, los
              Usuarios podrán oponerse a dicho tratamiento explicando un motivo
              con relación a su situación particular para justificar su
              objeción.
            </span>
          </p>
          <p className="c13">
            <span>
              Los Usuarios deben saber que, sin embargo, en caso de que sus
              Datos Personales sean tratados con fines comerciales, pueden
              oponerse en cualquier momento a tal tratamiento sin necesidad de
              justificación. Para saber si los Datos Personales de los Usuarios
              están siendo tratados por parte del Titular para fines
              comerciales, éstos deberán consultar las secciones relevantes del
              presente documento.
            </span>
          </p>
          <h3 className="c11" id="h.wusc4qo18io">
            <span className="c0">Cómo ejercer estos derechos</span>
          </h3>
          <p className="c7">
            <span>
              Cualquier solicitud para ejercer los derechos del Usuario puede
              dirigirse al Titular a través de los datos de contacto facilitados
              en el presente documento. Dichas solicitudes serán tramitadas por
              el Titular sin coste alguno tan pronto como le sea posible y
              siempre dentro del plazo de un mes.
            </span>
          </p>
          <h2 id="h.2j3gzh9gispv">
            <span>Aplicación de estándares de protección más elevados</span>
          </h2>
          <p>
            <span>
              Mientras que todos los Usuarios cuentan con los estándares de
              protección básicos, algunos Usuarios cuentan con estándares de
              protección más elevados.
            </span>
          </p>
          <p className="c13">
            <span>
              Dichos estándares de protección más elevados siempre se aplicarán
              cuando el tratamiento:
            </span>
          </p>
          <ul className="c14 lst-kix_bi9qk8qfblhy-0 start">
            <li className="c8 li-bullet-0">
              <span>lo realice un Titular residente en la UE;</span>
            </li>
            <li className="c8 li-bullet-0">
              <span>
                sea relativo a los Datos Personales de Usuarios que se
                encuentren en la UE y además sea relativo a publicitar bienes y
                servicios, de pago o gratuitos, a dichos Usuarios.
              </span>
            </li>
            <li className="c8 li-bullet-0">
              <span>
                sea relativo a los Datos Personales de Usuarios que se
                encuentren en la UE y además permitan al Titular monitorizar el
                comportamiento de dichos Usuarios en el territorio de la UE.
              </span>
            </li>
          </ul>
          <h2 id="h.xbcdog358fm">
            <span>
              Información adicional sobre la recogida de Datos y su tratamiento
            </span>
          </h2>
          <h3 className="c11" id="h.gf8zvi1n7vgr">
            <span className="c0">Defensa jurídica</span>
          </h3>
          <p className="c7">
            <span>
              Los Datos Personales del Usuario podrán ser utilizados para la
              defensa jurídica del Titular ante un tribunal o en las fases
              judiciales previas a un posible pleito derivado del uso
              inapropiado de Fanz o de los Servicios relacionados.
              <br />
              El Usuario declara conocer que el Titular puede ser requerido por
              las autoridades públicas a fin de revelar Datos Personales.
            </span>
          </p>
          <h3 className="c11" id="h.dvk3us3b0l12">
            <span className="c0">
              Información adicional acerca de los Datos Personales del Usuario
            </span>
          </h3>
          <p className="c7">
            <span>
              Además de las informaciones contenidas en esta política de
              privacidad, Fanz podrá proporcionar al Usuario información
              adicional y contextual relativa a Servicios específicos o a la
              recogida y tratamiento de los Datos Personales.
            </span>
          </p>
          <h3 className="c11" id="h.829jh0pdbhjo">
            <span className="c0">Log del sistema y mantenimiento</span>
          </h3>
          <p className="c7">
            <span>
              Por motivos relativos al funcionamiento y mantenimiento, Fanz y
              cualquier otro servicio, proporcionado por terceros, que se
              utilice, podrá recoger un registro del sistema; es decir, archivos
              que registren la interacción con Fanz y que puedan contener Datos
              Personales, tales como la dirección IP del Usuario.
            </span>
          </p>
          <h3 className="c11" id="h.97h5jn79chjk">
            <span className="c0">
              Información no contenida en esta política de privacidad
            </span>
          </h3>
          <p className="c7">
            <span>
              Se podrá solicitar en cualquier momento información adicional
              sobre la recogida y el tratamiento de los Datos Personales al
              Titular. La información de contacto se indica al inicio del
              presente documento.
            </span>
          </p>
          <h3 className="c11" id="h.p9j8wf3g1cnb">
            <span className="c0">
              Modificación de la presente política de privacidad
            </span>
          </h3>
          <p className="c7">
            <span>
              El Titular se reserva el derecho de modificar esta política de
              privacidad en cualquier momento, notificándolo a los Usuarios a
              través de esta página y, a ser posible, a través de Fanz y/o de
              ser técnica y legalmente posible notificando directamente a los
              Usuarios, en caso de que el Titular cuente con la información de
              contacto necesaria a tal fin. Se recomienda encarecidamente que
              revisen esta página con frecuencia, tomando como referencia la
              fecha de la última actualización indicada al final de la página.
              <br />
              <br />
              En el caso de que los cambios afectasen a las actividades de
              tratamiento realizadas en base al consentimiento del Usuario, el
              Titular deberá obtener, si fuera necesario, el nuevo
              consentimiento del Usuario.
            </span>
          </p>
          <p>
            <span />
          </p>
          <p>
            <span>Seguridad</span>
          </p>
          <p>
            <span>
              Nos comprometemos a proteger la información personal de nuestros
              usuarios. Utilizamos medidas de seguridad razonables para proteger
              contra la pérdida, el uso indebido y la alteración de la
              información personal bajo nuestro control.
            </span>
          </p>
        </Box>
        <div
          id="reembolsos"
          style={{
            paddingTop: "60px",
            marginTop: "-60px",
            display: "block",
            visibility: "hidden",
            position: "relative",
          }}
        />
        <BlockTitle
          tagline="Last Updated: October 25, 2023"
          heading="Política de Reembolso"
          id="reembolsos"
        />
        <Box sx={styles.terms}>
          <h2 className="c0">
            <span className="c4">Reembolsos</span>
          </h2>
          <p className="c0">
            <span className="c1">
              No hay reembolsos, créditos o intercambios de boletos. Esto se
              aplica a eventos que han sido pospuestos y aún esperan ser
              reprogramados. Las tarifas de Fanz no son reembolsables en ninguna
              circunstancia.
            </span>
          </p>
          <p className="c0 c2">
            <span className="c1" />
          </p>
          <p className="c0">
            <span className="c1">
              Sin embargo, pueden aplicarse excepciones si el organizador del
              evento elige habilitar la reventa de Fanz en lugar de un
              reembolso, para recircular los boletos que ya no pueden ser
              utilizados por el titular del boleto existente. Si se habilita la
              Reventa para su evento, se puede hacer disponible una opción para
              vender su(s) boleto(s) dentro de "Mis Tickets". En algunos casos,
              esta opción estará disponible en una fecha posterior, antes de la
              fecha del evento.
            </span>
          </p>
          <p className="c0 c2">
            <span className="c1" />
          </p>
          <p className="c0">
            <span className="c1">
              Cuando un boleto ha sido revendido a través de la Reventa de Fanz,
              el titular del boleto original es elegible para un reembolso del
              componente de valor nominal de su compra de boleto original,
              sujeto al pago de una Tarifa de Reventa para cubrir los costos
              administrativos.
            </span>
          </p>
          <p className="c0 c2">
            <span className="c1" />
          </p>
          <h2 className="c0">
            <span className="c4 c5">
              Obtener un reembolso para un evento cancelado o pospuesto
            </span>
          </h2>
          <p className="c0">
            <span className="c1">
              En caso de que un evento haya sido pospuesto, es responsabilidad
              exclusiva del organizador del evento publicar sus planes para
              cualquier nueva fecha y cambios en la programación del evento,
              lugar, horarios y términos. Los organizadores del evento también
              pueden optar por ofrecer reembolsos discrecionales caso por caso.
            </span>
          </p>
          <p className="c0 c2">
            <span className="c1" />
          </p>
          <p className="c0">
            <span className="c1">
              El organizador del evento notificará a los titulares de boletos
              sobre un evento cancelado o pospuesto por correo electrónico y / o
              a través de su sitio web oficial o cuentas de redes sociales.
              Especificarán las opciones disponibles para los titulares de
              boletos que no puedan asistir a la nueva fecha. A menos que los
              términos del evento lo indiquen específicamente, cuando no se
              prevé una reprogramación y solo una vez que Fanz haya recibido la
              confirmación oficial de esto por parte del organizador del evento,
              Fanz podrá activar una instrucción de reembolso en la cuenta del
              organizador del evento. Esto puede ser para emitir un reembolso
              parcial o completo del valor nominal de la entrada de regreso a la
              tarjeta de pago utilizada para la compra. Todos los reembolsos,
              por cualquier motivo o circunstancia, son responsabilidad
              exclusiva del organizador del evento. Las tarifas de Fanz no son
              reembolsables en ninguna circunstancia.
            </span>
          </p>
          <p className="c0">
            <span className="c1">
              Cuando se emita un reembolso, se enviará una confirmación por
              correo electrónico a la dirección de correo electrónico
              proporcionada en el momento de la compra. Los reembolsos pueden
              tardar hasta 10 días hábiles en acreditarse en su tarjeta,
              dependiendo de su banco o emisor de tarjeta.
            </span>
          </p>
          <p className="c0 c2">
            <span className="c1" />
          </p>
          <h2 className="c0">
            <span className="c5 c4">Términos Adicionales</span>
          </h2>
          <p className="c0">
            <span>
              Los Términos y Condiciones de Venta de Fanz se aplican a todas las
              compras realizadas en o a través de app.fanz.events, sujetos a la
              exclusión de términos específicos en relación a reembolsos, donde
              un Organizador de Eventos elige aplicar una política de reembolso
              personalizada para un Evento. En tales casos, los términos
              sustitutivos se mostrarán al momento de la compra y anularán
              solamente los términos relacionados proporcionados por Fanz. En
              ninguna circunstancia la política de reembolso personalizada de un
              Organizador de Eventos será válida si se pretende transferir la
              responsabilidad de los reembolsos, de cualquier tipo, a Fanz.
            </span>
          </p>
        </Box>
        {/* <BlockTitle
          tagline="Last Updated: October 25, 2023"
          heading="Licencia del Software"
          id="licencia-de-software"
        /> */}
      </Container>
    </Box>
  );
};

export default RecursosLegales;

const styles = {
  box: {
    marginBottom: "60px",
  },
  container: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    overflow: "hidden",
  },
  terms: {
    padding: "20px 30px",
    borderRadius: "8px",
    flexDirection: "column",
    margin: 0,
    width: "100%",
    textAlign: "left",
    background: "#f1f1f1",
    div: {
      position: "relative",
      padding: "20px",
      borderRadius: "5px",
      background: "#ededed",
      boxShadow: "1px 1px 3px #d4d4d4",
      "@media (max-width: 810px)": {
        marginBottom: "30px",
      },
    },
    p: {
      margin: 0,
      width: "100%",
      textAlign: "justify",
      "@media (max-width: 810px)": {
        fontSize: "15px",
      },
    },
    ul: {
      listStyleType: "disc",
      ul: {
        listStyleType: "circle",
      },
    },
  },
};
