import React, { useState } from "react";
import {
  Box,
  Container,
  Typography,
  useTheme,
  useMediaQuery,
} from "@mui/material";
import affiliateImage from "../../assets/fanz-affiliates.png";
import SEO from "../../components/SEO/SEO";

const Afiliados = () => {
  const [showIframe, setShowIframe] = useState(false);
  const theme = useTheme();
  const isTablet = useMediaQuery(theme.breakpoints.down("lg")); // 'lg' corresponds to 1200px in MUI's default breakpoints
  const isMobile = useMediaQuery(theme.breakpoints.down("md")); // 'md' corresponds to 1000px in MUI's default breakpoints

  const handleApplyClick = () => {
    setShowIframe(true);
  };

  const styles = {
    image: {
      maxWidth: "545px",
      borderRadius: "30px",
    },
    box: {
      marginBottom: "60px",
    },
    container: {
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      overflow: "hidden",
      padding: isTablet ? "60px 30px" : `${ showIframe ? "60px" : "150px"} 0`
    },
    terms: {
      padding: "20px 30px",
      borderRadius: "8px",
      flexDirection: "column",
      margin: 0,
      width: "100%",
      textAlign: "left",
      background: "#f1f1f1",
      div: {
        position: "relative",
        padding: "20px",
        borderRadius: "5px",
        background: "#ededed",
        boxShadow: "1px 1px 3px #d4d4d4",
        "@media (max-width: 810px)": {
          marginBottom: "30px",
        },
      },
      title: {
        paddingTop: "1em",
        textAlign: "center",
        margin: "0px",
        color: "rgb(18, 15, 67)",
        fontSize: "38px",
        fontWeight: "700",
        marginBottom: ".5em",
      },
      p: {
        margin: 0,
        width: "100%",
        textAlign: "justify",
        "@media (max-width: 810px)": {
          fontSize: "15px",
        },
      },
      ul: {
        listStyleType: "disc",
        ul: {
          listStyleType: "circle",
        },
      },
    },
  };

  return (
    <Box id="terms" sx={styles.box}>
      <Box style={{ width: "100%", height: "100px", background: "#050327" }} />
      <SEO
        title="Fanz | Afiliados"
        description="Programa de Afiliados de Fanz"
        image="%PUBLIC_URL%/og_image.jpg"
      />
      <Container sx={styles.container}>
        {!showIframe && (
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: isMobile ? "center" : "space-between",
              gap: "60px",
              flexDirection: isMobile ? "column-reverse" : "row",
            }}
          >
            <div
              style={{
                width: isTablet ? "100%" : "45%",
              }}
            >
              <h5
                style={{
                  fontWeight: "700",
                  letterSpacing: "2px",
                  fontSize: "1em",
                  color: "#603CFF",
                  width: "100%",
                  marginBottom: "10px",
                }}
              >
                ¡Ganá 50% con cada evento que traigas!
              </h5>
              <h2 style={{ fontSize: isTablet ? "32px" : "46px" }}>
                <span style={{ whiteSpace: "nowrap" }}>
                  ¡Ganá un generoso <u style={{ color: "#603CFF" }}>50%</u>
                </span>{" "}
                <span style={{ whiteSpace: "nowrap" }}> de lo que recaude fanz con cada venta que nos traigas!</span>
                <b> Sumate a nuestro programa de afiliados!</b>
              </h2>
              <p style={{ color: "#4A5568", margin: "1rem 0 2rem 0" }}>
                Sumate a nuestro programa de Afiliados y llevate la mitad de las ganancias de Fanz por cada evento que consigas para nuestra plataforma.
                Es fácil y rápido. ¡No hay límites en lo que podés ganar!
              </p>
              <div
                onClick={handleApplyClick}
                style={{
                  cursor: "pointer",
                  fontWeight: "bolder",
                  display: "inline-flex",
                  alignItems: "center",
                  justifyContent: "center",
                  userSelect: "none",
                  position: "relative",
                  whiteSpace: "nowrap",
                  verticalAlign: "middle",
                  outline: "transparent solid 2px",
                  outlineOffset: "2px",
                  width: "330px",
                  lineHeight: "1.2",
                  borderRadius: "45px",
                  transition: "all 0.25s ease 0s",
                  boxSizing: "border-box",
                  height: "54px",
                  paddingLeft: "16px",
                  paddingRight: "16px",
                  background:
                    "linear-gradient(15.46deg, rgb(74, 37, 225) 26.3%, rgb(123, 90, 255) 86.4%)",
                  color: "white",
                  paddingTop: "20px",
                  paddingBottom: "20px",
                }}
              >
                ¡Postulate ahora!
              </div>
            </div>
            <div
              style={{
                width: isTablet ? "100%" : "45%",
                display: "flex",
                alignItems: "center",
                justifyContent: isMobile ? "center" : "flex-end",
              }}
            >
              <img
                src={affiliateImage}
                alt="Affiliates"
                style={{
                  maxWidth: "100%", // Image scales down but never up
                  width: "100%", // Image will occupy full width of its container
                  borderRadius: "30px",
                }}
              />
            </div>
          </div>
        )}

        {showIframe && (
          <>
            <Typography
              as="h2"
              style={{
                paddingTop: "1em",
                textAlign: "center",
                margin: "0px",
                color: "rgb(18, 15, 67)",
                fontSize: "38px",
                fontWeight: "700",
                marginBottom: ".5em",
              }}
            >
              Aplicá al programa de afiliados
            </Typography>
            <Typography
              as="p"
              style={{
                paddingTop: 0,
                paddingBottom: "2em",
                textAlign: "center",
                margin: "0px",
                color: "black",
                fontSize: "20px",
                fontWeight: "400",
                marginBottom: ".5em",
              }}
            >
              Dá el primer paso para unirte a Fanz Afiliados. ¡Agenda la llamada
              y conocé todas las chances que te esperan!
            </Typography>
            <iframe
              title={"hubspot"}
              id="calendar"
              style={{
                width: "100%",
                height: "750px",
                border: 0,
                frameBorder: 0,
              }}
              src={
                "https://calendar.google.com/calendar/appointments/schedules/AcZssZ3tiVaJXC72sqNAVndWHBatZcg09ZmvmW4VVwPrOFgxUC-wlORgdqGxcSrK2ws2b2Lz3If4wa5I?gv=true"
              }
            />
          </>
        )}

        {/* <div style={{ display: "flex", gap: "20px", marginTop: "120px" }}>
          <div
            style={{
              background: "#fff",
              boxShadow: "rgba(112, 144, 176, 0.08) 14px 17px 40px 4px",
              borderRadius: "16px",
              display: "flex",
              alignItems: "flex-start",
              gap: "10px",
              padding: "20px 20px 30px",
            }}
          >
            <div
              style={{
                width: "120px",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                fontSize: "30px",
                background: "#F4F7FE",
                color: "#603CFF",
                height: "50px",
                borderRadius: "8px",
              }}
            >
              1
            </div>
            <div style={{ color: "#4A5568", padding: "0 8px" }}>
              <p
                style={{
                  fontWeight: "700",
                  marginTop: "0",
                  color: "rgb(26, 32, 44)",
                  fontSize: "1.2em",
                }}
              >
                Agenda una demo
              </p>
              <p style={{ fontWeight: "500" }}>
                We use Tolt.io for the affiliate program. Head over toTolt.io
                and create a free account.
              </p>
            </div>
          </div>
          <div
            style={{
              background: "#fff",
              boxShadow: "rgba(112, 144, 176, 0.08) 14px 17px 40px 4px",
              borderRadius: "16px",
              display: "flex",
              alignItems: "flex-start",
              gap: "10px",
              padding: "20px 20px 30px",
            }}
          >
            <div
              style={{
                width: "120px",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                fontSize: "30px",
                background: "#F4F7FE",
                color: "#603CFF",
                height: "50px",
                borderRadius: "8px",
              }}
            >
              2
            </div>
            <div style={{ color: "#4A5568", padding: "0 8px" }}>
              <p
                style={{
                  fontWeight: "700",
                  marginTop: "0",
                  color: "rgb(26, 32, 44)",
                  fontSize: "1.1em",
                }}
              >
                Aplicá como afiliado
              </p>
              <p style={{ fontWeight: "500" }}>
                We’ll give you a unique affiliate link you can share on your
                website, blog, social media - wherever!
              </p>
            </div>
          </div>
          <div
            style={{
              background: "#fff",
              boxShadow: "rgba(112, 144, 176, 0.08) 14px 17px 40px 4px",
              borderRadius: "16px",
              display: "flex",
              alignItems: "flex-start",
              gap: "10px",
              padding: "20px 20px 30px",
            }}
          >
            <div
              style={{
                width: "120px",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                fontSize: "30px",
                background: "#F4F7FE",
                color: "#603CFF",
                height: "50px",
                borderRadius: "8px",
              }}
            >
              3
            </div>
            <div style={{ color: "#4A5568", padding: "0 8px" }}>
              <p
                style={{
                  fontWeight: "700",
                  marginTop: "0",
                  color: "rgb(26, 32, 44)",
                  fontSize: "1.1em",
                }}
              >
                Share Horizon UI PRO
              </p>
              <p style={{ fontWeight: "500" }}>
                The more you share, the more you earn. There's no limit to how
                much you can make as an affiliate.
              </p>
            </div>
          </div>
        </div> */}
      </Container>
    </Box>
  );
};

export default Afiliados;
