import { Box, Button, createStyles, Typography } from "@mui/material";

const CallToAction = () => {
  return (
    <Box sx={{ pb: 5}}>
      <Box sx={styles.ctaContainer}>
        <Button sx={styles.button} href="#solicitaunademo">
          Agenda una demo
        </Button>
        <Button sx={styles.buttonSecondary} href="https://panel.fanz.com.ar" rel="noreferrer" target="_blank">
          Empezá ahora
        </Button>
      </Box>
      <Typography
        sx={{
          color: "rgb(74, 85, 104)",
          textAlign: "center",
          fontWeight: "500",
          fontSize: "14px",
          margin: 0,
          "@media screen and (max-width: 830px)": {
            width: "70% !important",
            margin: "12px auto 0 auto",
          },
        }}
      >
        🧐 Todavía no te decidis?... Bueno, dejanos ayudarte a tomar la decisión
        correcta.
      </Typography>
    </Box>
  );
};

const styles = createStyles({
  ctaContainer: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    margin: "1em auto",
    width: "100%",
    gap: "10px",
    "@media (max-width: 610px)": {
      flexWrap: "wrap",
    },
  },
  button: {
    backgroundColor: "#9019fa",
    color: "#fff",
    fontSize: "16px",
    whiteSpace: "nowrap",
    height: "48px",
    cursor: "pointer",
    padding: "0.5em 1em",
    background:
      "linear-gradient(15.46deg, rgb(74, 37, 225) 26.3%, rgb(123, 90, 255) 86.4%)",
    boxShadow: "none",
    maxWidth: "250px",
    borderRadius: "45px",
    transition: "all .5s ease-in-out",
    "-webkit-text-fill-color": "#fff !important",
    width: "100%",
    "@media screen and (max-width: 768px)": {
      margin: "0 auto",
    },
    "@media (max-width: 401px)": {
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      justifyContent: "center",
    },
    "&:hover": {
      backgroundColor: "#9019fa",
      boxShadow: "none",
      color: "#fff",
      "-webkit-text-fill-color": "#fff !important",
    },
  },
  buttonSecondary: {
    fontSize: "16px",
    whiteSpace: "nowrap",
    height: "48px",
    background: "transparent",
    borderRadius: "45px",
    cursor: "pointer",
    padding: "0.5em 1em",
    border: "1px solid #4a25e1",
    maxWidth: "250px",
    width: "100%",
    "-webkit-text-fill-color": "#4a25e1",
    boxShadow: "none",
    "&:hover": {
      background: "rgb(98 64 240 / 3%)",
    },
  },
});

export default CallToAction;
