import { Card, Container, CardMedia } from "@mui/material";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

export default function ProfileCard({ items = [] }) {
  const settings = {
    infinite: true,
    centerMode: false,
    autoplay: true,
    speed: 2000,
    autoplaySpeed: 2500,
    cssEase: "linear",
    slidesToShow: 6,
    slidesToScroll: 1,
    initialSlide: 0,
    variableWidth: false,
    responsive: [
      {
        breakpoint: 1240,
        settings: {
          slidesToShow: 5,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 4,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 900,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
          autoplaySpeed: 2000,
        },
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          autoplaySpeed: 1500,
        },
      },
      {
        breakpoint: 530,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          autoplaySpeed: 1000,
        },
      },
    ],
  };

  return (
    <Container sx={styles.container}>
      <h3>
        Fanz <span>Army</span>
      </h3>
      <Slider {...settings}>
        {items.map((item, index) => (
          <div key={index}>
            <Card
              sx={{
                position: "relative",
                width: "auto",
                marginBottom: "100px",
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                justifyContent: "center",
                height: "auto",
              }}
            >
              <CardMedia
                component="img"
                height="194"
                image={Object.values(item.path)[0]}
                alt={item.path}
              />
              <div style={{ width: "140px", textAlign: "center" }}>
                <p>{item.name}</p>
                <p>{item.role}</p>
              </div>
            </Card>
          </div>
        ))}
      </Slider>
    </Container>
  );
}

const styles = {
  container: {
    padding: 0,
    width: "1100px",
    "@media (max-width: 1240px)": {
      width: "975px",
    },
    "@media (max-width: 1024px)": {
      width: "760px",
    },
    "@media (max-width: 900px)": {
      width: "605px",
    },
    "@media (max-width: 768px)": {
      width: "455px",
    },
    "@media (max-width: 530px)": {
      width: "290px",
    },
    "@media (max-width: 320px)": {
      width: "160px",
    },
    h3: {
      marginTop: 0,
      fontSize: "24px",
      textAlign: "center",
      fontWeight: "bolder",
    },
    p: {
      textAlign: "center",
      marginTop: "10px",
      lineHeight: "normal",
      fontSize: "12px",
      marginBottom: 0,
      color: "black",
      textDecoration: "none",
    },
    "p:last-child": {
      textAlign: "center",
      fontSize: "12px",
      lineHeight: "normal",
      whiteSpace: "nowrap",
      fontWeight: "bolder",
      color: "black",
      textDecoration: "none",
    },
    span: {
      background: "#d6e9ff",
      color: "#0d89ff",
      padding: "0 6px",
    },
  },
  social: {
    width: "35px",
    height: "auto",
    color: "primary.main",
    padding: 0,
    fontSize: 30,
    flexShrink: 0,
    marginTop: "3px",
    marginBottom: "3px",
  },
};
