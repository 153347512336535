import { Outlet } from "react-router-dom";
import { Footer } from "../../components";
import { createTheme } from "../../theme";
import { ThemeProvider } from "@mui/material/styles";
import CssBaseline from "@mui/material/CssBaseline";
import { NavbarOrganizers } from "../Navbar";
import { WhatsAppWidget } from "react-whatsapp-widget";
import "react-whatsapp-widget/dist/index.css";
import "./Layout.css"
import Logo from "../Logo/Logo";

const Layout = () => {
  return (
    <>
      <ThemeProvider
        theme={createTheme({
          direction: "rtl",
          responsiveFontSizes: true,
          mode: "light",
        })}
      >
        <CssBaseline />
        <NavbarOrganizers />
        <Outlet />
        <Footer />
        <WhatsAppWidget
          CompanyIcon={Logo}
          companyName="Fanz"
          replyTimeText="Responde en minutos"
          inputPlaceHolder="Escribí un mensaje"
          sendButtonText="Enviar"
          message="Hola! 👋🏼 ¿Como te puedo ayudar?"
          phoneNumber="5491165631954"
        />
      </ThemeProvider>
    </>
  );
};

export default Layout;
