// import React from "react";
// import {
//   // Box,
//   Container,
//   Typography,
//   createStyles,
//   // Button,
// } from "@mui/material";
// import { CallToAction } from "../../components";

// const HowItWorks = () => {
//   return (
//     <Container sx={styles.container}>
//       <Typography variant={"h2"}>
//         Software para la venta de entradas y control de aforo
//       </Typography>
//       <Typography variant={"body1"} style={{ textAlign: "center" }}>
//         Ya sea para conciertos, festivales, funciones de teatro,{" "}
//         <b style={{ color: "#0d89ff", fontWeight: "bold" }}>Fanz PRO</b> se
//         moldea a todas las exigencias de los organizadores de eventos. Ofrece
//         una plataforma todo-en-uno, completamente autogestionable, para manejar
//         y supervisar tanto tus propios eventos como los de terceros, todo ello
//         sin comisiones.
//       </Typography>
//       <Typography variant={"body1"} style={{ marginTop: "1em" }}>
//         <a
//           href="#solicitaunademo"
//           style={{ color: "#0d89ff", fontWeight: "bold" }}
//         >
//           Agendá una demo
//         </a>{" "}
//         y enterate de como podes aprovechar nuestra tecnología para{" "}
//         <i>agotar tus entradas</i> en tiempo récord...
//       </Typography>
//       {/* <CallToAction /> */}
//     </Container>
//   );
// };

// export default HowItWorks;

// const styles = createStyles({
//   container: {
//     marginTop: "6em",
//     marginBottom: "8em",
//     display: "flex",
//     flexDirection: "column",
//     alignItems: "center",
//     justifyContent: "center",
//     h2: {
//       margin: "0 0 .5em 0",
//       color: "rgb(18, 15, 67)",
//       fontSize: "44px",
//       lineHeight: [1.6, null, null, "1.5"],
//       fontWeight: "bold",
//       letterSpacing: ["-0.5px", null, null, null, null, null, "-1.5px"],
//       textAlign: "center",
//       "@media (max-width: 430px)": {
//         fontSize: "36px",
//       },
//     },
//     "@media (max-width: 430px)": {
//       textAlign: "center",
//       marginTop: "3em",
//       marginBottom: "4em",
//     },
//   },
// });
import React from "react";
import { Box, Container, Typography, createStyles } from "@mui/material";
import SpreadTheWordSRC from "../../assets/spread-the-word.png";

const bullets = [
  {
    icon: (
      <svg
        stroke="currentColor"
        fill="currentColor"
        stroke-width="0"
        viewBox="0 0 24 24"
        focusable="false"
        class="chakra-icon css-u9d50o"
        height="1em"
        width="1em"
        xmlns="http://www.w3.org/2000/svg"
        style={{
          width: 23,
          height: 23,
          color: "#4a25e1",
          borderRadius: "50%",
          marginRight: "10px",
        }}
      >
        <path fill="none" d="M0 0h24v24H0z"></path>
        <path d="M16 6l2.29 2.29-4.88 4.88-4-4L2 16.59 3.41 18l6-6 4 4 6.3-6.29L22 12V6z"></path>
      </svg>
    ),
    text: "Ticketera Marca blanca",
  },
  {
    icon: (
      <svg
        stroke="currentColor"
        fill="currentColor"
        stroke-width="0"
        viewBox="0 0 24 24"
        focusable="false"
        class="chakra-icon css-u9d50o"
        height="1em"
        width="1em"
        xmlns="http://www.w3.org/2000/svg"
        style={{
          width: 23,
          height: 23,
          color: "#4a25e1",
          borderRadius: "50%",
          marginRight: "10px",
        }}
      >
        <path fill="none" d="M0 0h24v24H0z"></path>
        <path d="M11.99 18.54l-7.37-5.73L3 14.07l9 7 9-7-1.63-1.27-7.38 5.74zM12 16l7.36-5.73L21 9l-9-7-9 7 1.63 1.27L12 16z"></path>
      </svg>
    ),
    text: "Inversión post-compra",
  },
  {
    icon: (
      <svg
        stroke="currentColor"
        fill="currentColor"
        stroke-width="0"
        viewBox="0 0 24 24"
        focusable="false"
        class="chakra-icon css-u9d50o"
        height="1em"
        width="1em"
        xmlns="http://www.w3.org/2000/svg"
        style={{
          width: 23,
          height: 23,
          color: "#4a25e1",
          borderRadius: "50%",
          marginRight: "10px",
        }}
      >
        <path fill="none" d="M0 0h24v24H0V0z"></path>
        <path d="M12 2C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2zm0 18c-4.41 0-8-3.59-8-8s3.59-8 8-8 8 3.59 8 8-3.59 8-8 8zm.31-8.86c-1.77-.45-2.34-.94-2.34-1.67 0-.84.79-1.43 2.1-1.43 1.38 0 1.9.66 1.94 1.64h1.71c-.05-1.34-.87-2.57-2.49-2.97V5H10.9v1.69c-1.51.32-2.72 1.3-2.72 2.81 0 1.79 1.49 2.69 3.66 3.21 1.95.46 2.34 1.15 2.34 1.87 0 .53-.39 1.39-2.1 1.39-1.6 0-2.23-.72-2.32-1.64H8.04c.1 1.7 1.36 2.66 2.86 2.97V19h2.34v-1.67c1.52-.29 2.72-1.16 2.73-2.77-.01-2.2-1.9-2.96-3.66-3.42z"></path>
      </svg>
    ),
    text: "30 veces mejor que pubilicidad paga",
  },
  {
    icon: (
      <svg
        stroke="currentColor"
        fill="currentColor"
        stroke-width="0"
        viewBox="0 0 24 24"
        focusable="false"
        class="chakra-icon css-u9d50o"
        height="1em"
        width="1em"
        xmlns="http://www.w3.org/2000/svg"
        style={{
          width: 23,
          height: 23,
          color: "#4a25e1",
          borderRadius: "50%",
          marginRight: "10px",
        }}
      >
        <path d="M11 21h-1l1-7H7.5c-.58 0-.57-.32-.38-.66.19-.34.05-.08.07-.12C8.48 10.94 10.42 7.54 13 3h1l-1 7h3.5c.49 0 .56.33.47.51l-.07.15C12.96 17.55 11 21 11 21z"></path>
      </svg>
    ),
    text: "Miles de clics sin pagar por tráfico.",
  },
];

const ReferralsProgram = () => {
  return (
    <Container sx={styles.container}>
      <div
        style={{
          display: "flex",
        }}
      >
        <div>
          <Typography variant={"h2"}>
            Software de gestión de{" "}
            <span style={{ whiteSpace: "nowrap" }}> venta de entradas</span>{" "}
            <span style={{ position: "relative", whiteSpace: "nowrap" }}>
              Todo-en-uno
              <svg
                viewBox="0 0 272 8"
                focusable="false"
                class="chakra-icon chakra-icon css-fsr128"
                style={{
                  width: "100%",
                  position: "absolute",
                  bottom: "-12px",
                  left: "0",
                }}
              >
                <svg
                  width="272"
                  height="8"
                  viewBox="0 0 272 8"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    fill-rule="evenodd"
                    clip-rule="evenodd"
                    d="M269.324 7.99445C165.26 1.51861 48.1761 5.29673 2.66743 7.99495C1.28982 8.07663 0.0981205 7.15583 0.00570267 5.93829C-0.0867152 4.72075 0.955142 3.66752 2.33276 3.58584C47.9765 0.879607 165.306 -2.90852 269.675 3.58635C271.053 3.67205 272.091 4.72832 271.994 5.94559C271.897 7.16285 270.702 8.08016 269.324 7.99445Z"
                    fill="url(#paint0_linear_999_11627)"
                  ></path>
                  <defs>
                    <linearGradient
                      id="paint0_linear_999_11627"
                      x1="24.9606"
                      y1="6.97522"
                      x2="209.694"
                      y2="-91.8367"
                      gradientUnits="userSpaceOnUse"
                    >
                      <stop stop-color="#3D1DFF"></stop>
                      <stop offset="0.223953" stop-color="#6147FF"></stop>
                      <stop offset="0.46354" stop-color="#D451FF"></stop>
                      <stop offset="0.750004" stop-color="#EC458D"></stop>
                      <stop offset="1" stop-color="#FFCA8B"></stop>
                    </linearGradient>
                  </defs>
                </svg>
              </svg>
            </span>
          </Typography>
          <Typography variant={"body1"} style={{ marginTop: "2em" }}>
            Recitales, festivales, teatro y más: <b>Fanz PRO</b> se adapta a vos
            y tu público, facilitando la gestión de eventos en una plataforma
            autogestionable, en minutos y sin costos extra.
          </Typography>
          <div style={styles.container.bullets}>
            {bullets.map((bullet, idx) => (
              <p
                style={{
                  maxWidth: "550px",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: [
                    "flex-start",
                    null,
                    null,
                    null,
                    null,
                    "flex-start",
                  ],
                  color: "#000",
                  textAlign: "left",
                  fontSize: "20px",
                  lineHeight: [2.56],
                  marginBottom: "1em",
                }}
                key={idx}
              >
                {bullet.icon}
                <Typography
                  variant="body1"
                  style={{ fontWeight: "700", color: "#4a25e1" }}
                >
                  {bullet.text}
                </Typography>
              </p>
            ))}
          </div>
        </div>
        <Box sx={styles.imageContainer}>
          <img alt="spread the word" src={SpreadTheWordSRC} width="100%" />
        </Box>
      </div>
      {/* <CallToAction/> */}
    </Container>
  );
};

export default ReferralsProgram;

const styles = createStyles({
  imageContainer: {
    maxWidth: "600px",
    display: "flex",
    alignItems: "center",
    "@media (max-width: 800px)": {
      display: "none",
    },
  },
  container: {
    bullets: {
      margin: "30px 0 0 0",
      padding: 0,
      display: "grid",
      gridTemplateColumns: "1fr 1fr",
      gridTemplateRows: "1fr 1fr",
      width: "100%",
      ml: "10px",
      mb: 0,
      mt: ["30px"],
    },
    marginTop: "6em",
    marginBottom: "8em",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    p: {
      fontSize: "18px",
    },
    h2: {
      margin: "0 0 .5em 0",
      color: "rgb(18, 15, 67)",
      fontSize: "36px",
      lineHeight: [1.6, null, null, "1.5"],
      fontWeight: "bold",
      letterSpacing: ["-0.5px", null, null, null, null, null, "-1.5px"],
      textAlign: "left",
      "@media (max-width: 620px)": {
        textAlign: "center",
      },
      "@media (max-width: 430px)": {
        fontSize: "30px",
      },
    },
    "@media (max-width: 430px)": {
      textAlign: "center",
      marginTop: "3em",
      marginBottom: "4em",
    },
  },
});
