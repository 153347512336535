import { Box, Typography } from "@mui/material";

const BlockTitle = (props) => {
  return (
    <Box sx={styles}>
      <Typography as="p">{props.tagline}</Typography>
      <Typography as="h3">{props.heading}</Typography>
    </Box>
  );
};

export default BlockTitle;

const styles = {
    p: {
      margin: 0,
      fontSize: '14px',
      fontWeight: 'Bold',
      textTransform: 'uppercase',
      color: 'black',
      lineHeight: 1,
      letterSpacing: '2.1px',
      marginBottom: '12px',
    },
    h3: {
      margin: 0,
      fontSize: ['24px', null, null, '30px', null, null, '36px'],
      color: 'primary.main',
      fontWeight: 'bold',
      lineHeight: 1.53,
      whiteSpace: 'pre-line',
      letterSpacing: ['-0.5px', null, null, '-1.5px'],
    },
    textAlign: "center",
    py: 5
  };
