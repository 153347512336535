import { Helmet } from "react-helmet";

const BlockTitle = (props) => {
	const {
		title,
		description,
		image
	} = props;

  return (
		<Helmet>
			<title>Fanz - Gestión de eventos 3.0</title>
			<meta charset="utf-8" />
			<meta name="viewport" content="width=device-width, initial-scale=1" />
			<meta name="author" content="Fanz" />
			<meta name="description" content={description} />
			<meta name="keywords" content="Fanz,tickets,events,event,fanz,eventos,fiesta,fiestas,entrada,entradas,festivales,festival,gestiondeeventos" />
			<meta name="theme-color" content="#111827" />
			<meta name="title" content={title} />
			<meta name="meta_title" content={title} />
			<meta property="og:title" content={title} />
			<meta property="og:description" content={description} />
			<meta property="og:image" content={image ? image : "%PUBLIC_URL%/og_image.jpg"} />
			<meta property="og:type" content="website" />
			<meta name="twitter:title" content={title} />
			<meta name="twitter:description" content={description} />
			<meta property="twitter:image" content={image ? image : "%PUBLIC_URL%/og_image.jpg"} />
			<meta name="twitter:card" content="summary_large_image" />
			<meta name="twitter:creator" content="twitter.com/FanzEvents" />
		</Helmet>
  );
};

export default BlockTitle;