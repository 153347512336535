import { Container, Typography, createStyles, Box } from "@mui/material";
import ComparisonTable from "../../assets/tabla-comparativa.jpg";

// const planHeaders = [
//   "Características",
//   "Ticketeras Tradicionales",
//   <img src={FanzLogo} alt="fanz-logo" width="70px" />,
//   <div
//     style={{ display: "flex", alignItems: "center", justifyContent: "center" }}
//   >
//     <img src={FanzLogo} alt="fanz-logo" width="70px" />
//     <span
//       style={{
//         marginLeft: "4px",
//         whiteSpace: "nowrap",
//         verticalAlign: "middle",
//         paddingInline: "12px",
//         textTransform: "none",
//         fontSize: "0.50rem",
//         borderRadius: "25px",
//         fontWeight: "700",
//         background:
//           "linear-gradient(15.46deg, rgb(74, 37, 225) 26.3%, rgb(156, 132, 255) 91.13%)",
//         color: "#fff",
//         letterSpacing: "0px",
//         display: "flex",
//         width: "30px",
//         height: "15px",
//         alignItems: "center",
//         justifyContent: "center",
//         textDecoration: "none",
//       }}
//     >
//       PRO
//     </span>
//   </div>,
// ];

// const planFeatures = [
//   {
//     label: "Integración con MercadoPago",
//     values: [
//       "Variable",
//       <MDBIcon fas icon="check" style={{ color: "#00e537" }} />,
//       <MDBIcon fas icon="check" style={{ color: "#00e537" }} />,
//     ],
//   },
//   {
//     label: "Notificaciones de Ventas via WhatsApp",
//     values: [
//       "-",
//       <MDBIcon fas icon="check" style={{ color: "#00e537" }} />,
//       <MDBIcon fas icon="check" style={{ color: "#00e537" }} />,
//     ],
//   },
//   {
//     label: "Gestión de Cupones de Descuentos",
//     values: [
//       "-",
//       <MDBIcon fas icon="check" style={{ color: "#00e537" }} />,
//       <MDBIcon fas icon="check" style={{ color: "#00e537" }} />,
//     ],
//   },
//   {
//     label: "Manejo de Base de Datos y Segmentación",
//     values: [
//       "-",
//       <MDBIcon fas icon="check" style={{ color: "#00e537" }} />,
//       <MDBIcon fas icon="check" style={{ color: "#00e537" }} />,
//     ],
//   },
//   {
//     label: "Sistema para Grandes Equipos y Roles",
//     values: [
//       "-",
//       <MDBIcon fas icon="check" style={{ color: "#00e537" }} />,
//       <MDBIcon fas icon="check" style={{ color: "#00e537" }} />,
//     ],
//   },
//   {
//     label: "Herramientas de Marketing y Análisis",
//     values: [
//       "Limitadas",
//       <MDBIcon fas icon="check" style={{ color: "#00e537" }} />,
//       <MDBIcon fas icon="check" style={{ color: "#00e537" }} />,
//     ],
//   },
//   {
//     label: "Sistema para Grandes Equipos y Roles",
//     values: [
//       "-",
//       <MDBIcon fas icon="check" style={{ color: "#00e537" }} />,
//       <MDBIcon fas icon="check" style={{ color: "#00e537" }} />,
//     ],
//   },
//   {
//     label: "Gestión de RRPP, Validadores y Taquilleros",
//     values: [
//       "No o Limitadas",
//       <MDBIcon fas icon="check" style={{ color: "#00e537" }} />,
//       <MDBIcon fas icon="check" style={{ color: "#00e537" }} />,
//     ],
//   },
//   {
//     label: "Programa de Referidos",
//     values: [
//       "-",
//       <MDBIcon fas icon="check" style={{ color: "#00e537" }} />,
//       <MDBIcon fas icon="check" style={{ color: "#00e537" }} />,
//     ],
//   },
//   {
//     label: "Pagos Instantáneos",
//     values: [
//       "-",
//       <MDBIcon fas icon="check" style={{ color: "#00e537" }} />,
//       <MDBIcon fas icon="check" style={{ color: "#00e537" }} />,
//     ],
//   },
//   {
//     label: "Vender bajo propio dominio",
//     values: [
//       "-",
//       "-",
//       <MDBIcon fas icon="check" style={{ color: "#00e537" }} />,
//     ],
//   },
//   {
//     label: "Personalización de Branding",
//     values: [
//       "-",
//       "-",
//       <MDBIcon fas icon="check" style={{ color: "#00e537" }} />,
//     ],
//   },
//   {
//     label: "Envío de tickets vía WhatsApp",
//     values: [
//       "-",
//       "-",
//       <MDBIcon fas icon="check" style={{ color: "#00e537" }} />,
//     ],
//   },
//   {
//     label: "Asistente de Inteligencia Artificial (Fanzly)",
//     values: [
//       "-",
//       "-",
//       <MDBIcon fas icon="check" style={{ color: "#00e537" }} />,
//     ],
//   },
//   {
//     label: "Soporte",
//     values: ["Variable", "Estándar", "24/7 Personalizado"],
//   },
//   {
//     label: "Comisiones por venta",
//     values: ["15%", "6%", "0%"],
//   },
//   {
//     label: "",
//     values: [
//       "",
//       <MDBBtn>Crea tu evento</MDBBtn>,
//       <MDBBtn>Convertite en Pro</MDBBtn>,
//     ],
//   },
// ];

const featureComparisonSection = () => {
  return (
    <Container sx={styles.container} id="comparativa">
      <Typography variant={"h2"}>Conocé lo que Nos Diferencia</Typography>
      {/* <FeatureComparisonTable
        planHeaders={planHeaders}
        planFeatures={planFeatures}
      /> */}
      <Box
        component="img"
        alt="logo of fanz"
        width="100%"
        style={{
          maxWidth: "800px",
        }}
        src={ComparisonTable}
      />
    </Container>
  );
};

const styles = createStyles({
  container: {
    paddingTop: "60px",
    marginTop: "-60px",
    marginBottom: "5em",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    h2: {
      margin: "0 0 2em 0",
      color: "rgb(18, 15, 67)",
      fontSize: "36px",
      lineHeight: [1.6, null, null, "1.5"],
      fontWeight: "bold",
      letterSpacing: ["-0.5px", null, null, null, null, null, "-1.5px"],
      textAlign: "center",
      "@media (max-width: 430px)": {
        fontSize: "36px",
      },
    },
    "@media (max-width: 430px)": {
      textAlign: "center",
      marginTop: "3em",
      marginBottom: "4em",
    },
  },
});

export default featureComparisonSection;
