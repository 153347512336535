import React from "react";
import { Box, Container, Typography, Button } from "@mui/material";
import BgShape from "../../assets/cta-shape-1.svg";
import btnShape from "../../assets/cta-btn-shape-1.svg";

const CallToAction = () => {

  return (
    <Box sx={styles.wrapper}>
      <Container sx={styles.container}>
        <Box sx={styles.inner}>
          <Typography variant="h4">¿Está interesado? Únase a nuestro Discord</Typography>
          <Box>
            <a target="_blank" href="https://discord.gg/mKk6y9HR6h" style={{textDecoration: "none"}} rel="noreferrer">
              <Button sx={styles.btn}>JOIN DISCORD</Button>
            </a>
          </Box>
        </Box>
      </Container>
    </Box>
  );
};

export default CallToAction;

const styles = {
  wrapper: {
    margin: "0 auto",
    maxWidth: "1140px",
    marginBottom: "60px"
  },
  inner: {
    padding: ["45px 30px 50px", null, null, "45px 50px", null, "45px 70px"],
    mt: [0, null, null, null, "80px"],
    position: "relative",
    zIndex: "10",
    backgroundColor: "primary.main",
    backgroundImage: `url(${BgShape})`,
    backgroundRepeat: "no-repeat",
    backgroundPosition: "60% center",
    borderRadius: "8px",
    display: "flex",
    alignItems: "center",
    textAlign: ["center", null, null, "left"],
    flexDirection: ["column", null, null, "row"],
    justifyContent: ["center", null, null, "space-between"],
    h4: {
      color: "#fff",
    },
  },
  btn: {
    backgroundColor: "#fff",
    color: "primary.main",
    borderRadius: "5px",
    fontWeight: "700",
    letterSpacing: "0.1em",
    padding: ["10px 24px", null, null, null, null, "15px 44px"],
    position: "relative",
    outline: "none",
    webkitAppearance: "none",
    "&:before, &:after": {
      content: '""',
      position: "absolute",
      width: "73px",
      height: "26px",
      backgroundImage: `url(${btnShape})`,
      backgroundRepeat: "no-repeat",
      backgroundPosition: "center",
      bottom: "calc(100% + 10px)",
      left: "50%",
      transform: "translateX(-50%)",
    },
    "&:after": {
      bottom: "auto",
      top: "calc(100% + 10px)",
      transform: "translateX(-50%) rotate(180deg)",
    },
    "&:hover": {
      backgroundColor: "black",
      color: "#fff",
    },
  },
};
