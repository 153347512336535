import { Box, Typography } from "@mui/material";
import React from "react";
import ArmozaLogo from "../../assets/clients/armoza.png";
import CamarinLogo from "../../assets/clients/elcamarindelasmusas.png";
import EndeavorLogo from "../../assets/clients/endeavor.png";
import TodoTicketLogo from "../../assets/clients/todoticket.png";
import GoetheSchuleLogo from "../../assets/clients/goethe-schule.png";
import TeatroAdnLogo from "../../assets/clients/teatroadn.png";

const Testimonials = ({ inDemo = undefined }) => {
  // const [showMore, setShowMore] = useState(false);

  // const handleToggle = () => {
  //   setShowMore((prev) => !prev);
  // };

  return (
    <Box component="section" sx={styles(inDemo).fevCourse}>
      <Box sx={styles(inDemo).fevCourse.container}>
        <Typography variant="h2" sx={{ pt: inDemo ? 3 : 0 }}>Historias de Éxito</Typography>
        
        <Box sx={styles(inDemo).imagesContainer}>
          {/* Video 1 */}
          <Box sx={styles(inDemo).videoWrapper}>
            <Box sx={styles(inDemo).iframeContainer}>
              <iframe
                src="https://www.youtube.com/embed/D8DUZ7__WTE"
                title="YouTube video player"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; fullscreen"
                allowFullScreen
              ></iframe>
            </Box>
            <Box>
              <Typography sx={styles(inDemo).videoTitle} variant="subtitle1">
                The Suburban Players
              </Typography>
              <Typography sx={styles(inDemo).videoDescription} variant="subtitle2">
                El club de teatro de habla inglesa más antiguo de Argentina
              </Typography>
              <a href={"https://tickets.thesuburbanplayers.com"} target="_blank" rel="noreferrer">
                <Typography sx={styles(inDemo).webLink} variant="subtitle2">
                  Ver sitio web
                </Typography>
              </a>
            </Box>
          </Box>

          {/* Video 2 */}
          <Box sx={styles(inDemo).videoWrapper}>
            <Box sx={styles(inDemo).iframeContainer}>
              <iframe
                src="https://www.youtube.com/embed/4Dq9tP46x34"
                title="YouTube video player"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; fullscreen"
                allowFullScreen
              ></iframe>
            </Box>
            <Typography sx={styles(inDemo).videoTitle} variant="subtitle1">
              Enigmax
            </Typography>
            <Typography sx={styles(inDemo).videoDescription} variant="subtitle2">
              Experiencia teatral inmersiva.
            </Typography>
            <a href={"https://tickets.enigmaxoficial.com"} target="_blank" rel="noreferrer">
                <Typography sx={styles(inDemo).webLink} variant="subtitle2">
                  Ver sitio web
                </Typography>
              </a>
          </Box>

          {/* Video 3 */}
          <Box sx={styles(inDemo).videoWrapper}>
            <Box sx={styles(inDemo).iframeContainer}>
              <iframe
                src="https://www.youtube.com/embed/O9RbYQga8wk"
                title="YouTube video player"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; fullscreen"
                allowFullScreen
              ></iframe>
            </Box>
            <Typography sx={styles(inDemo).videoTitle} variant="subtitle1">
              Colegio Northlands
            </Typography>
            <Typography sx={styles(inDemo).videoDescription} variant="subtitle2">
              Obras musicales, ferias, y eventos de recaudación.
            </Typography>
            <a href={"https://northlands-events.com"} target="_blank" rel="noreferrer">
                <Typography sx={styles(inDemo).webLink} variant="subtitle2">
                  Ver sitio web
                </Typography>
              </a>
          </Box>
        </Box>

        {/* Logos Section */}
        <Box sx={styles(inDemo).logosContainer}>
              <Box sx={styles(inDemo).wrapper}>
                <Box sx={styles(inDemo).logoWrapper}>
                  <img src={CamarinLogo} alt="Camarin de las musas Logo" />
                </Box>
                <Box sx={styles(inDemo).clientContent}>
                  <Box sx={styles(inDemo).wrapper}>
                    <Typography variant="subtitle2" sx={styles(inDemo).clientTitle}>
                      El Camarín de las Musas
                    </Typography>
                    <Typography variant="subtitle2" sx={styles(inDemo).videoDescription}>
                      Teatro de artes escénicas en Buenos Aires
                    </Typography>
                  </Box>
                  <a href={"https://elcamarindelasmusas.com"} target="_blank" rel="noreferrer">
                    <Typography sx={styles(inDemo).webLink} variant="subtitle2">
                      Ver sitio web
                    </Typography>
                  </a>
                </Box>
              </Box>
              <Box sx={styles(inDemo).wrapper}>
                <Box sx={styles(inDemo).logoWrapper}>
                  <img src={ArmozaLogo} alt="Armoza Logo" />
                </Box>
                <Box sx={styles(inDemo).clientContent}>
                  <Box>
                    <Typography variant="subtitle2" sx={styles(inDemo).clientTitle}>
                      Centro Cultural Armoza
                    </Typography>
                    <Typography variant="subtitle2" sx={styles(inDemo).videoDescription}>
                      Vinoteca - Eventos - Ajedrez - Arte - Literatura
                    </Typography>
                  </Box>
                  <a href={"https://ccarmoza.com"} target="_blank" rel="noreferrer">
                    <Typography sx={styles(inDemo).webLink} variant="subtitle2">
                      Ver sitio web
                    </Typography>
                  </a>
                </Box>
              </Box>
              <Box sx={styles(inDemo).wrapper}>
                <Box sx={styles(inDemo).logoWrapper}>
                  <img src={EndeavorLogo} alt="Endeavor Logo" />
                </Box>
                <Box sx={styles(inDemo).clientContent}>
                  <Box>
                    <Typography variant="subtitle2" sx={styles(inDemo).clientTitle}>
                      Endeavor
                    </Typography>
                    <Typography variant="subtitle2" sx={styles(inDemo).videoDescription}>
                      La red de apoyo emprendedor más grande del mundo
                    </Typography>
                  </Box>
                  <a href={"https://eventos-endeavor.com/"} target="_blank" rel="noreferrer">
                    <Typography sx={styles(inDemo).webLink} variant="subtitle2">
                      Ver sitio web
                    </Typography>
                  </a>
                </Box>
              </Box>
              <Box sx={styles(inDemo).wrapper}>
                <Box sx={styles(inDemo).logoWrapper}>
                  <img src={TodoTicketLogo} alt="Todoticket" />
                </Box>
                <Box sx={styles(inDemo).clientContent}>
                  <Box>
                    <Typography variant="subtitle2" sx={styles(inDemo).clientTitle}>
                      Todoticket
                    </Typography>
                    <Typography variant="subtitle2" sx={styles(inDemo).videoDescription}>
                      Circo Houdini - Circo Gueorgue - Familiy Circus - Cirque XXI
                    </Typography>
                  </Box>
                  <a href={"https://boletas.todoticket.ar/"} target="_blank" rel="noreferrer">
                    <Typography sx={styles(inDemo).webLink} variant="subtitle2">
                      Ver sitio web
                    </Typography>
                  </a>
                </Box>
              </Box>
              <Box sx={styles(inDemo).wrapper}>
                <Box sx={styles(inDemo).logoWrapper}>
                  <img src={GoetheSchuleLogo} alt="Armoza Logo" />
                </Box>
                <Box sx={styles(inDemo).clientContent}>
                  <Box>
                    <Typography variant="subtitle2" sx={styles(inDemo).clientTitle}>
                      Goethe Schule
                    </Typography>
                    <Typography variant="subtitle2" sx={styles(inDemo).videoDescription}>
                      Escuela Alemana en Argentina
                    </Typography>
                  </Box>
                  <a href={"https://events.goethe.edu.ar/"} target="_blank" rel="noreferrer">
                    <Typography sx={styles(inDemo).webLink} variant="subtitle2">
                      Ver sitio web
                    </Typography>
                  </a>
                </Box>
              </Box>
              <Box sx={styles(inDemo).wrapper}>
                <Box sx={styles(inDemo).logoWrapper}>
                  <img src={TeatroAdnLogo} alt="Endeavor Logo" />
                </Box>
                <Box sx={styles(inDemo).clientContent}>
                  <Box>
                    <Typography variant="subtitle2" sx={styles(inDemo).clientTitle}>
                      Teatro Artes del Norte
                    </Typography>
                    <Typography variant="subtitle2" sx={styles(inDemo).videoDescription}>
                      Teatro de artes escénicas en Salta
                    </Typography>
                  </Box>
                  <a href={"https://teatroadnticket.com/"} target="_blank" rel="noreferrer">
                    <Typography sx={styles(inDemo).webLink} variant="subtitle2">
                      Ver sitio web
                    </Typography>
                  </a>
                </Box>
              </Box>
            </Box>
          {/* {showMore && (
            <Box sx={styles(inDemo).imagesContainer}>
             
            </Box>
          )} */}

        {/* View More/View Less Button */}
        {/* <Button onClick={handleToggle} sx={styles(inDemo).viewMoreButton}>
          {showMore ? "Ver menos" : "Ver más"}
          {showMore ? <BiChevronUp /> : <BiChevronDown />}
        </Button> */}
      </Box>
    </Box>
  );
};

export default Testimonials;

const styles = (inDemo = false) => ({
  fevCourse: {
    marginBottom: inDemo ? 0 : "80px",
    marginTop: "-1px",
    borderBottomLeftRadius: inDemo ? 0 : "80px",
    borderBottomRightRadius: inDemo ? 0 : "80px",
    background: "#050327",
    paddingBottom: "35px",
    "@media (max-width: 1057px)": {
      marginBottom: inDemo ? 0 : "50px",
    },
    h2: {
      margin: "0 0 16px 0",
      fontSize: "20px",
      color: "#fff",
    },
    container: {
      display: "flex",
      alignItems: "center",
      maxWidth: "1140px",
      width: "80%",
      margin: "0 auto",
      flexDirection: "column",
    },
  },
  wrapper: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "space-between",
  },
  clientContent: {
    maxWidth: "150px",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "space-between",
    height: "165px",
  },
  imagesContainer: {
    display: "flex",
    justifyContent: "space-between",
    flexWrap: "nowrap",
    gap: "1.5em",
    width: "100%",
    margin: "1.5em 0",
    "@media (max-width: 1057px)": {
      flexWrap: "wrap",
      justifyContent: "center",
    },

  },
  logosContainer: {
    display: "grid",
    gridTemplateColumns: "repeat(6, 1fr)",
    gridGap: "1.5em",
    width: "100%",
    "@media (max-width: 1280px)": {
      gridColumnGap: 0,
    },
    "@media (max-width: 1120px)": {
      gridTemplateColumns: "repeat(3, 1fr)",
    },
    "@media (max-width: 475px)": {
      gridTemplateColumns: "repeat(2, 1fr)",
    }
  },
  videoWrapper: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    width: "100%",
    maxWidth: "560px",
  },
  iframeContainer: {
    position: "relative",
    width: "100%",
    paddingTop: "56.25%",
    background: "#000",
    overflow: "hidden",
    marginBottom: "0.5em",
    "& iframe": {
      position: "absolute",
      top: 0,
      left: 0,
      width: "100%",
      height: "100%",
    },
  },
  logoWrapper: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    width: "100%",
    paddingBottom: 2,
    "& img": {
      width: "120px",
      height: "120px",
      maxHeight: "150px",
      maxWidth: "150px",
    },
  },
  clientTitle: {
    fontSize: "16px",
    color: "#fff",
    textAlign: "center",
    marginBottom: "0.5em",
    fontWeight: "bold",
  },
  videoTitle: {
    fontSize: "16px",
    color: "#fff",
    textAlign: "center",
    marginBottom: "0.5em",
    fontWeight: "bold",
  },
  videoDescription: {
    fontSize: "14px",
    color: "#ccc",
    textAlign: "center",
    marginBottom: "1em",
  },
  webLink: {
    textAlign: "center",
    width: "100%",
  },
  viewMoreButton: {
    marginTop: "1.5em",
    color: "#fff",
    background: "none", // Remove background
    padding: "0", // Remove padding to make it inline
    fontSize: "16px", // Match the font size with other text
    "&:hover": {
      opacity: .5, // Darker blue on hover
      cursor: "pointer", // Pointer cursor to indicate it's clickable
      textDecoration: "none", // Optionally remove underline on hover
    },
  },
});
