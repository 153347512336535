import React from "react";
import { Box, Container, Typography } from "@mui/material";
import billboardLogo from "../../assets/Billboard_logo.png";
import { createStyles } from "@mui/material/styles";

const QuoteBlock = () => {
  return (
    <Box>
      <Container>
        <Box sx={styles.quoteContainer}>
          <Box sx={styles.svgContainer}>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="45"
              height="45"
              viewBox="0 0 24 24"
              fill="#0d89ff"
              style={{
                transform: "scaleX(-1)",
              }}
            >
              <path d="M9.983 3v7.391c0 5.704-3.731 9.57-8.983 10.609l-.995-2.151c2.432-.917 3.995-3.638 3.995-5.849h-4v-10h9.983zm14.017 0v7.391c0 5.704-3.748 9.571-9 10.609l-.996-2.151c2.433-.917 3.996-3.638 3.996-5.849h-3.983v-10h9.983z" />
            </svg>
          </Box>
          <Box sx={styles.quote}>
            <Box sx={styles.box}>
              <Typography variant="h5">
                La plataforma de ticketing que está cambiando las reglas del juego
              </Typography>
            </Box>
            <Box sx={styles.box}>
              <img src={billboardLogo} alt="Billboard Logo" />
            </Box>
          </Box>
          <Box sx={styles.svgContainer}>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="45"
              height="45"
              viewBox="0 0 24 24"
              fill="#0d89ff"
            >
              <path d="M9.983 3v7.391c0 5.704-3.731 9.57-8.983 10.609l-.995-2.151c2.432-.917 3.995-3.638 3.995-5.849h-4v-10h9.983zm14.017 0v7.391c0 5.704-3.748 9.571-9 10.609l-.996-2.151c2.433-.917 3.996-3.638 3.996-5.849h-3.983v-10h9.983z" />
            </svg>
          </Box>
        </Box>
      </Container>
    </Box>
  );
};

export default QuoteBlock;

const styles = createStyles({
  quoteContainer: {
    display: "flex",
    flexDirection: "column",
    margin: "0 auto 1em auto",
    maxWidth: "600px",
  },
  svgContainer: {
    display: "flex",
    margin: "0 0 1em 0",
    "&:last-child": {
      margin: "1em 0 0 0",
      justifyContent: "flex-end",
    },
  },
  box: {
    borderRadius: "8px",
    background: "#f7fbff",
    border: "2px solid #c4e2ff",
    boxShadow: "1px 1px 10px #eaeaea",
    maxWidth: "400px",
    width: "100%",
    margin: "0 auto",
    fontSize: "3em",
    padding: "1em",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    h5: {
      fontSize: "1em",
    },
    img: {
      maxWidth: "120px",
    },
    position: "relative",
    right: "60px",
    "&:last-child": {
      marginTop: ".5em",
      right: "unset",
      left: "120px",
      width: "fit-content"
    },
    "@media (max-width: 768px)": {
      h5: {
        fontSize: ".7em"
      },
      "&:last-child": {
        marginTop: "30px",
      },
    },
    "@media (max-width: 580px)": {
      right: "unset",
      "&:last-child": {
        left: "unset",
        width: "100%"
      }
    },
  },
});
