import LogoColor from '../../assets/fanz-logo.svg';
import { Link } from 'react-router-dom';

export default function Logo() {
  return (
    <Link
      to="/"
    >
      <img src={LogoColor} alt="startup landing logo" style={styles}/>
    </Link>
  );
}

const styles = {
  maxWidth: "50px",
  width: "50px"
}