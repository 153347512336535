import {
  Testimonials,
  HeaderOrganizers,
  CallToActionOrganizers,
  AppFeaturesOrganizers,
} from "../../container";
import SEO from "../../components/SEO/SEO";

const demoProps = {
  calendar: "https://meetings.hubspot.com/jneuss/30-min-demo?embed=true",
  title: "Agendá una llamada con nuestro CEO",
  subtitle:
    "El equipo de Fanz analizará tu caso para encontrar la mejor solución.",
  cta: "Agenda una demo",
};

const callProps = {
  calendar:
    "https://meetings.hubspot.com/jneuss/30-min-demo?embed=true",
  title: "Reserva un horario de llamada",
  subtitle:
    "Te contactará alguien del equipo de Fanz para ayudarte de la mejor manera posible.",
  cta: "Elegí un horario de llamada",
};

const LandingOrganizers = ({ isDemo }) => {
  return (
    <>
      <div>
        <SEO
          title="Fanz - Gestión de eventos 3.0"
          description="Transformá compradores en promotores, vendé con tu marca, con asientos numerados, recibí pagos al instante y deja que la IA te ayude a vender más"
          image="%PUBLIC_URL%/og_image.jpg"
        />
        <HeaderOrganizers cta={isDemo ? demoProps.cta : callProps.cta} />
        <Testimonials />
        {/* <WhatDoYouGet /> */}
        {/* <FeatureComparisonSection /> */}
        {/* <CallToAction/> */}
        {/* <ReferralsProgram /> */}
        <AppFeaturesOrganizers />
        {/* <BrandComparison /> */}
        <CallToActionOrganizers
          calendar={isDemo ? demoProps.calendar : callProps.calendar}
          title={isDemo ? demoProps.title : callProps.title}
          subtitle={isDemo ? demoProps.subtitle : callProps.subtitle}
        />
        {/* <FAQOrganizers /> */}
      </div>
    </>
  );
};

export default LandingOrganizers;
