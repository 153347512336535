import { useCallback, useEffect, useState } from 'react';
import { CircularProgress, Box, Container } from "@mui/material";
import * as DOMPurify from 'dompurify';
import { useParams } from 'react-router-dom';
import BlockTitle from '../../components/BlockTitle/BlockTitle';
import SEO from "../../components/SEO/SEO";


const safeText = (description) => {
  const clean = DOMPurify.sanitize(description, { USE_PROFILES: { html: true } })
  return clean; 
}

const decodeHtmlCharEntity = str =>{
  var textArea = document.createElement('textarea');
  textArea.innerHTML = str;
  return textArea.value;
}

const PostContent = () => {
	const { id } = useParams();
	const [post, setPost] = useState({});
  const [loading, setLoading] = useState(false);

  const getPosts = useCallback(async () => {
    try {
      const data = await fetch("https://api.rss2json.com/v1/api.json?rss_url=https://medium.com/feed/@fanzevents/")
      .then(res => {
        if (res.ok) {
          return res.json();
        } 
          throw res
        })
      .catch((err) => console.error(err));

      if(data.items){
        // eslint-disable-next-line eqeqeq
        setPost(data.items.find((post, index) => index == id));
      }

    } catch (err) {
      setLoading(false);
    }
  }, [id]);

	useEffect(() => {
    setLoading(true);
    getPosts().finally(() => {
      setLoading(false);
    });
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (loading ? <CircularProgress /> : <>
    {post &&
			<Box id="terms" sx={styles.box}>
        <SEO
          title={post.title}
          description="" 
          image={post.image} />
      	<Container sx={styles.container}>
					<BlockTitle
            tagline="Discover the latest news, tips and research insights from Fanz"
            heading={decodeHtmlCharEntity(post.title)}
          />
					<Box sx={styles.terms}>
						<div dangerouslySetInnerHTML={{ __html: safeText(post.content) }} />
					</Box>
				</Container>
			</Box>}
		</>
  );
};

export default PostContent;

const styles = {
	box: {
    marginBottom: "60px",
  },
  container: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    overflow: "hidden",
  },
	terms: {
    paddingBottom: "80px",
    listStyleType: "disc",
    gap: "15px",
    "@media (max-width: 810px)": {
      gap: 0,
      flexWrap: "wrap",
      paddingBottom: "20px",
    },
		position: "relative",
		padding: "20px",
		borderRadius: "5px",
		background: 'linear-gradient(to bottom, transparent, 40%, #d0d0d0)',
    p: {
      margin: 0,
      width: "100%",
      textAlign: "justify",
      "@media (max-width: 810px)": {
        fontSize: "15px",
      },
    },
		a: {
			textDecoration: "none",
			color: "primary.main"
		},
		figure: {
			maxWidth: '100%',
			height: 'auto',
			textAlign: 'center',
			'&:first-of-type': {
      	height: '400px',
    	},
		},
		img: {
			mb: 2,
			maxWidth: '100%',
			height: 'auto',
			'&:first-of-type': {
      	height: '100%',
    	},
		},
	}
}