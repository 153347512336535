import React from "react";
import { IoIosCheckmarkCircle } from "react-icons/io";
import { useTheme } from "@mui/material/styles";
import { Box, Typography, useMediaQuery } from "@mui/material";
import { CallToAction } from "../../components";
import ia from "../../assets/IA.png";
import ReactPlayer from "react-player";

const AppFeature = ({
  title,
  subtitle,
  bullets,
  image,
  imageLeft,
  videoSrc,
  screenshot,
  poweredByAi
}) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("md"));

  const imgStyle = isMobile
    ? { maxWidth: "100%", height: "auto", borderRadius: "8px" }
    : { height: "450px", borderRadius: "8px" };
  const styles = {
    createEvents: {
      position: "relative",
      overflow: "visible",
      container: {
        display: "flex",
        alignItems: "center",
        maxWidth: "1100px",
        justifyContent: "space-around",
        flexDirection: imageLeft ? "row" : "row-reverse",
        width: "80%",
        margin: "0 auto 80px auto",
        flexWrap: "nowrap",
        gap: "5px",
        "@media (max-width: 1057px)": {
          flexWrap: "wrap",
          flexDirection: "column-reverse",
          gap: 0,
          margin: "0 auto 40px auto",
          alignItems: "center",
        },
        "@media (max-width: 450px)": {
          width: "90%",
        },
        imageContainer: {
          "@media (min-width: 768px):": {
            width: "50%",
          },
          "@media (max-width: 450px)": {
            width: "auto",
          },
        },
        contentContainer: {
          "@media (min-width: 768px):": {
            width: "50%",
          },
          "@media (max-width: 450px)": {
            width: "auto",
          },
        },
      },
    },
    image: { ...imgStyle, position: "relative" },
    bullets: {
      margin: 0,
      padding: 0,
      listStyle: "none",
      ml: ["25px", null, null, "0"],
      mb: ["10px"],
      mt: ["30px"],
      li: {
        display: "flex",
        alignItems: "center",
        lineHeight: "35px",
        padding: "10px 0",
        justifyContent: ["flex-start", null, null, null, null, "flex-start"],
        color: "text_secondary",
        fontSize: "20px",
        svg: {
          width: "23px",
          height: "23px",
          minWidth: "23px",
          minHeight: "23px",
          color: "#DADADA",
          borderRadius: "50%",
          marginRight: ["10px"],
        },
      },
      "@media (max-width: 1057px)": {
        marginBottom: "45px",
      },
    },
    title: {
      position: "relative",
      textAlign: ["center", null, null, "left"],
      width: "400px",
      "@media (max-width: 450px)": {
        width: "auto",
      },
      h2: {
        margin: "0 0 .5em 0",
        color: "rgb(18, 15, 67)",
        fontSize: "36px",
        lineHeight: [1.6, null, null, "1.5"],
        fontWeight: "bold",
        letterSpacing: ["-0.5px", null, null, null, null, null, "-1.5px"],
      },
      p: {
        fontSize: "20px",
        color: "text_secondary",
        mt: ["15px"],
      },
      span: {
        color: "#0d89ff",
        padding: "0 0 0 0",
        fontWeight: "bolder",
      },
    },
    content: {
      width: "fit-content",
      textAlign: ["left", null, null, null, "left"],
    },
  };

  return (
    <>
    <Box id="body" sx={styles.createEvents}>
      <Box sx={styles.createEvents.container}>
        <Box
          sx={styles.createEvents.container.imageContainer}
          style={styles.image}
        >
          { image && <img
            src={image}
            alt={title}
            style={
              screenshot
                ? {
                    ...imgStyle,
                    border: "1px solid #f1f1f1",
                    boxShadow: "1px 1px 10px #f5f5f5",
                  }
                : imgStyle
            }
          />}
          {
            videoSrc && (
              <ReactPlayer
                url={videoSrc}
                playing={true}
                style={imgStyle}
                loop={true}
                muted={true}
                controls={false}
                playsinline={true}
                id="background_video"
                width={"420px"}
                height={isMobile ? "100%" : "450px"}
              />
            )
          }
          <div
            style={{
              display: "flex",
              height: "230px",
              width: "350px",
              filter: "blur(60px)",
              position: "absolute",
              left: "50%",
              top: "50%",
              transform: "translate(-50%, -50%)",
              background: "rgb(72 149 229)",
              zIndex: "-1",
              opacity: ".1",
            }}
          />
        </Box>
        <Box sx={styles.createEvents.container.contentContainer}>
          <Box sx={styles.title}>
            <h2 dangerouslySetInnerHTML={title}></h2>
            {poweredByAi && <p style={{ fontStyle: "italic"}}>Potenciado por<span> IA <img src={ia} alt="AI logo" style={{ width: "35px"}}/></span></p>}
            <Typography as="body1">{subtitle}</Typography>
          </Box>
          <Box as="ul" sx={styles.bullets}>
            {bullets.map((bullet, idx) => (
              <Typography as="li" key={idx}>
                <IoIosCheckmarkCircle style={{ color: "#0E89FF" }} />
                {bullet}
              </Typography>
            ))}
          </Box>
        </Box>
      </Box>
    </Box>

    { title.__html === "Precios dinámicos" && <CallToAction />}
    </>
  );
};

export default AppFeature;
