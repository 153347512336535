import * as React from "react";
import { Box, Typography, Grid, Link } from "@mui/material";
import {
  FaDiscord,
  FaRedditAlien,
  FaTwitter,
  FaTelegramPlane,
  FaInstagram,
} from "react-icons/fa";

function Footer() {
  return (
    <Box
      sx={{
        backgroundColor: "#050327",
        color: "white",
        padding: "4rem 3rem 2rem 3rem",
        "@media (max-width: 475px)": {
          padding: "2rem 1.5rem",
        },
      }}
    >
      <Box
        sx={{
          maxWidth: "1100px",
          margin: "0 auto",
        }}
      >
        <Grid container spacing={2}>
          <Grid
            item
            xs={12}
            md={3}
            sx={{
              display: "flex",
              flexDirection: ["row", null, "column"],
              justifyContent: ["space-between", null, "flex-start"],
              alignItems: ["center", null, "flex-start"],
              marginRight: ["16px", null, 0],
              mb: [3, 3, 0],
            }}
          >
            <Typography
              variant="h4"
              fontWeight="bold"
              sx={{
                alignSelf: ["flex-start", null, "initial"],
                fontSize: { md: "2rem" },
                lineHeight: "24px",
                mb: [0, 0, 5],
              }}
            >
              Fanz
            </Typography>
            <Box
              sx={{
                display: "flex",
                gap: 1.5,
                flexDirection: ["row", null, "column"],
              }}
            >
              {renderIcon(
                FaInstagram,
                "Instagram",
                "https://www.instagram.com/fanzevents/"
              )}
              {renderIcon(
                FaTwitter,
                "Twitter",
                "https://twitter.com/FanzEvents"
              )}
              {renderIcon(
                FaTelegramPlane,
                "Telegram",
                "https://t.me/FanzEvents"
              )}
              {renderIcon(
                FaDiscord,
                "Discord",
                "https://discord.gg/mKk6y9HR6h"
              )}
              {renderIcon(
                FaRedditAlien,
                "Reddit",
                "https://www.reddit.com/r/fanzevents/"
              )}
            </Box>
          </Grid>
          <Grid item xs={6} md={3}>
            <Typography variant="h6" fontWeight="bold" sx={{ mb: 2 }}>
              Recursos
            </Typography>
            <Link
              href="/blog"
              sx={{
                mb: 1,
                display: "block",
                color: "white",
                textDecoration: "none",
                "&:hover": {
                  textDecoration: "none",
                },
              }}
            >
              Blog
            </Link>
            {/* <Link
              href="https://help.fanz.events/documentaci%C3%B3n"
              target="_blank"
              sx={{
                mb: 1,
                display: "block",
                color: "white",
                textDecoration: "none",
                "&:hover": {
                  textDecoration: "none",
                },
              }}
            >
              Tutoriales
            </Link> */}
            {/* <Link
              href="/afiliados"
              sx={{
                mb: 1,
                display: "flex",
                color: "white",
                textDecoration: "none",
                "&:hover": {
                  textDecoration: "none",
                },
              }}
            >
              Afiliados
              <span
                style={{
                  display: "inline",
                  background: "#a38efa",
                  marginLeft: "8px",
                  borderRadius: "15px",
                  padding: "3px 5px",
                  fontSize: "12px",
                  fontWeight: "bold",
                  color: "#4a25e1",
                  textDecoration: "none",
                }}
              >
                Ganá 50%
              </span>
            </Link> */}
            {/* <Link
              href="/"
              sx={{
                mb: 1,
                display: "block",
                color: "white",
                textDecoration: "none",
                "&:hover": {
                  textDecoration: "none",
                },
              }}
            >
              Recursos de Marca
            </Link> */}
          </Grid>
          <Grid item xs={6} md={3}>
            <Typography variant="h6" fontWeight="bold" sx={{ mb: 2 }}>
              Ayuda y Soporte
            </Typography>
            {/* <Link
              href="https://help.fanz.events"
              target="_blank"
              sx={{
                mb: 1,
                display: "block",
                color: "white",
                textDecoration: "none",
                "&:hover": {
                  textDecoration: "none",
                },
              }}
            >
              Centro de Ayuda
            </Link> */}
            <Link
              href="mailto:juli@fanz.com.ar"
              target="_blank"
              sx={{
                mb: 1,
                display: "block",
                color: "white",
                textDecoration: "none",
                "&:hover": {
                  textDecoration: "none",
                },
              }}
            >
              Soporte
            </Link>
            <Link
              href="mailto:juli@fanz.com.ar"
              target="_blank"
              sx={{
                mb: 1,
                display: "block",
                color: "white",
                textDecoration: "none",
                "&:hover": {
                  textDecoration: "none",
                },
              }}
            >
              Contacta con nosotros
            </Link>
            {/* <Link
              href="https://help.fanz.events/preguntas-frecuentes"
              target="_blank"
              sx={{
                mb: 1,
                display: "block",
                color: "white",
                textDecoration: "none",
                "&:hover": {
                  textDecoration: "none",
                },
              }}
            >
              Preguntas Frecuentes
            </Link> */}
          </Grid>
          <Grid item xs={6} md={3}>
            <Typography
              variant="h6"
              fontWeight="bold"
              sx={{ mb: 2, whiteSpace: "nowrap" }}
            >
              Recursos Legales
            </Typography>
            <Link
              href="./recursos-legales#terminos-y-condiciones"
              sx={{
                mb: 1,
                display: "block",
                color: "white",
                textDecoration: "none",
                "&:hover": {
                  textDecoration: "none",
                },
                whiteSpace: "nowrap",
              }}
            >
              Términos & Condiciones
            </Link>
            <Link
              href="./recursos-legales#politicas-de-privacidad"
              sx={{
                mb: 1,
                display: "block",
                color: "white",
                textDecoration: "none",
                "&:hover": {
                  textDecoration: "none",
                },
                whiteSpace: "nowrap",
              }}
            >
              Políticas de Privacidad
            </Link>
            <Link
              href="./recursos-legales#reembolsos"
              sx={{
                mb: 1,
                display: "block",
                color: "white",
                textDecoration: "none",
                "&:hover": {
                  textDecoration: "none",
                },
                whiteSpace: "nowrap",
              }}
            >
              Política de Reembolso
            </Link>
            {/* <Link
              href="./recursos-legales#licencia-de-software"
              sx={{
                mb: 1,
                display: "block",
                color: "white",
                textDecoration: "none",
                "&:hover": {
                  textDecoration: "none",
                },
                whiteSpace: "nowrap",
              }}
            >
              Licencia del Software
            </Link> */}
          </Grid>
        </Grid>
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            marginTop: "2rem",
            borderTop: "1px solid rgba(255, 255, 255, 0.1)",
            paddingTop: "1rem",
          }}
        >
          <Typography variant="body2" style={{ textAlign: "center" }}>
            2023 © Fanz Events S.R.L - Todos los derechos reservados
          </Typography>
        </Box>
      </Box>
    </Box>
  );
}

function renderIcon(IconComponent, label, href) {
  return (
    <Link
      href={href}
      sx={{ color: "white", textDecoration: "none" }}
      target="_blank"
    >
      <Box sx={{ display: "flex", alignItems: "center", gap: 1, mb: 1 }}>
        <IconComponent />
        <Typography sx={{ display: { xs: "none", md: "block" } }}>
          {label}
        </Typography>
      </Box>
    </Link>
  );
}

export default Footer;
