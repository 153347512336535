import React from "react";
import { Container, Typography, createStyles } from "@mui/material";
import { GiReceiveMoney, GiCash, GiDivergence } from "react-icons/gi";
import { MdEventSeat } from "react-icons/md";
import { TbBrowserCheck } from "react-icons/tb";
import { MdSupportAgent } from "react-icons/md";

const ICON_SIZE = 75;
const TITLE_COLOR = "rgb(18, 15, 67)";
const BOTTOM_MARGIN = "2em";

const featureData = [
  {
    IconComponent: GiReceiveMoney,
    title: "Liberación de fondos inmediata",
    description:
      "Conectá tu Mercado Pago y recibí los pagos de manera directa al momento de la compra, sin intermediarios.",
  },
  {
    IconComponent: TbBrowserCheck,
    title: "Vendé con tu marca",
    description:
      "Personalizá el logo, dominio, tipografías, colores y brindale a tu audiencia una marca para el recuerdo.",
  },
  {
    IconComponent: GiCash,
    title: "Tu propia ticketera",
    description:
      "Vendé sin limites como si fuera tu propia ticketera. Creala en minutos y empezá a vender.",
  },
  {
    IconComponent: MdEventSeat,
    title: "Asientos numerados",
    description:
      "Vendé entradas con asientos numerados y brindale a tus asistentes una experiencia única y personalizada con planos 3D.",
  },
  {
    IconComponent: MdSupportAgent,
    title: "Soporte 24/7",
    description:
      "Atención ininterrumpida para tus dudas o inconvenientes. Siempre presentes, día y noche, para garantizar tu tranquilidad.",
  },
  {
    IconComponent: GiDivergence,
    title: "Publicá eventos de terceros",
    description:
      "Ganá dinero con los eventos publicados en tu ticketera, el cargo por servicio es todo tuyo.",
  },
];

const FeatureCard = ({ IconComponent, title, description }) => (
  <div className="col">
    <div className="text-center px-xxl-4">
      <IconComponent
        size={ICON_SIZE}
        style={{ marginBottom: BOTTOM_MARGIN, fill: "#3a3587", color: "#fff" }}
      />
      <h3 className="h4 mb-2" style={{ color: TITLE_COLOR }}>
        {title}
      </h3>
      <p>{description}</p>
    </div>
  </div>
);

const WhatDoYouGet = () => {
  return (
    <Container sx={styles.container} id="beneficios">
      <Typography variant={"h2"}>¿Qué obtenés con Fanz PRO?</Typography>
      <div className="row mt-3 row-cols-1 row-cols-sm-2 row-cols-lg-3 gy-3 gy-sm-4 gy-xl-5 gx-4 gx-md-5 pb-xxl-4 mb-sm-2 mb-lg-0 mb-xl-2">
        {featureData.map((feature, index) => (
          <FeatureCard key={index} {...feature} />
        ))}
      </div>
    </Container>
  );
};

export default WhatDoYouGet;

const styles = createStyles({
  container: {
    margin: "6em auto",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    paddingTop: "70px",
    marginTop: "-70px",
    h2: {
      margin: "0 0 1em 0",
      color: TITLE_COLOR,
      fontSize: "36px",
      lineHeight: [1.6, null, null, "1.5"],
      fontWeight: "bold",
      letterSpacing: ["-0.5px", null, null, null, null, null, "-1.5px"],
      textAlign: "center",
      "@media (max-width: 430px)": {
        fontSize: "36px",
      },
    },
    "@media (max-width: 430px)": {
      textAlign: "center",
      marginTop: "3em",
      marginBottom: "4em",
    },
  },
});
