import { useCallback, useEffect, useState } from "react";
import { Box, Container, CircularProgress, Grid, Stack } from "@mui/material";
import BlockTitle from "../../components/BlockTitle/BlockTitle";
import BlogCard from "../../components/BlogCard/BlogCard";
import SEO from "../../components/SEO/SEO";

import * as DOMPurify from "dompurify";

const safeText = (description) => {
  const clean = DOMPurify.sanitize(description, {
    USE_PROFILES: { html: true },
  });
  return clean;
};

const decodeHtmlCharEntity = (str) => {
  const normalizedStr = str.normalize("NFD").replace(/[\u0300-\u036f]/g, "");
  var textArea = document.createElement("textarea");
  textArea.innerHTML = normalizedStr;
  return textArea.value;
};

const Blog = () => {
  const [posts, setPosts] = useState([]);
  const [loading, setLoading] = useState(false);

  const getPosts = useCallback(async () => {
    try {
      const data = await fetch(
        "https://api.rss2json.com/v1/api.json?rss_url=https://medium.com/feed/@fanzevents/"
      )
        .then((res) => {
          if (res.ok) {
            return res.json();
          }
          throw res;
        })
        .catch((err) => console.error(err));

      if (data.items) {
        setPosts(data.items);
      }
    } catch (err) {
      setLoading(false);
    }
  }, []);

  useEffect(() => {
    setLoading(true);
    getPosts().finally(() => {
      setLoading(false);
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const chunks = posts.reduce((acc, post, index) => {
    const chunkIndex = Math.floor(index / 5);
    if (!acc[chunkIndex]) {
      acc[chunkIndex] = [];
    }
    acc[chunkIndex].push(post);
    return acc;
  }, []);

  return loading ? (
    <Stack alignItems="center" padding={30}>
      <CircularProgress />
    </Stack>
  ) : (
    <Box id="blog" sx={styles.box}>
      <SEO
        title="Fanz | Blog"
        description="Discover the latest news, tips and research insights from Fanz"
        image="%PUBLIC_URL%/og_image.jpg"
      />
      <Box style={{ width: "100%", height: "100px", background: "#050327" }} />

      <Container sx={styles.container}>
        <BlockTitle
          tagline="Discover the latest news, tips and research insights from Fanz"
          heading="Blog"
        />
        <div style={{ flexGrow: 1, padding: 2 }}>
          {chunks.map((chunk, index) => (
            <Grid key={index} container spacing={5}>
              {chunk.map((item, chunkIndex) => (
                <Grid
                  item
                  key={item.title}
                  xs={12}
                  sm={6}
                  md={4}
                  lg={chunkIndex < 2 ? 6 : 4}
                >
                  <BlogCard
                    id={chunkIndex}
                    authorName={item.author}
                    categories={item.categories}
                    cover={item.thumbnail}
                    publishedAt={item.pubDate}
                    link={decodeHtmlCharEntity(safeText(item.title))}
                    title={safeText(item.title)}
                    content={decodeHtmlCharEntity(safeText(item.content))}
                    size={index < 2 ? "large" : "small"}
                  />
                </Grid>
              ))}
              <Box sx={{ p: 2.5 }}></Box>
            </Grid>
          ))}
        </div>
      </Container>
    </Box>
  );
};

export default Blog;

const styles = {
  box: {
    marginBottom: "60px",
  },
  container: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    overflow: "hidden",
  },
};
